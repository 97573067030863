import { User } from 'interfaces/user.interface';
import { RememberMe, UserState } from './user.interface';
import { useUserStore } from './user.store';

const userService = (state: UserState) => {
    return {
        isAuth: state.isAuth,
        isActiveUser: state.isActiveUser,
        user: state.user,
        rememberMe: state.rememberMe,
        token: state.token,
        refreshToken: state.refreshToken,
        setIsAuth: (status: boolean) => state.setIsAuth(status),
        setIsActiveUser: (status: boolean) => state.setIsActiveUser(status),
        setRememberMe: (data: RememberMe) => state.setRememberMe(data),
        setUser: (user: User) => state.setUser(user),
        setToken: (token: string) => state.setToken(token),
        setRefreshToken: (refreshToken: string) => state.setRefreshToken(refreshToken),
        clearUser: () => state.clearUser(),
        logout: () => state.logout(),
        clear: () => state.clear(),
        hasRole: (roles: string | string[]): boolean => {
            let access = false;
            roles = typeof roles === 'string' ? [roles] : roles;
            roles.every((role) => {
                if (state.user?.roles.includes(role)) {
                    access = true;
                    return false;
                }
                return true;
            });

            return access;
        },
    };
};

export const useUserService = (): any => {
    return useUserStore(userService);
};
