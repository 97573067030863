import { BooleanConverter, ImageConverter, InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const DietTypeProperties = {
    id: 'id',
    name: 'name',
    description: 'description',
    calories: 'calories',
    kilosPerMonth: 'kilosPerMonth',
    status: 'status',
    isPublic: 'isPublic',
    avatar: 'avatar',
    picto: 'picto',
    checkup: 'checkup',
    isNew: 'isNew',
    mostPopular: 'mostPopular',
};

const DietType: ResourceType = {
    resourcePath: 'bo-diet_types',
    placeHolder: {
        [DietTypeProperties.name]: '',
        [DietTypeProperties.description]: '',
        [DietTypeProperties.calories]: 0,
        [DietTypeProperties.kilosPerMonth]: 0,
        [DietTypeProperties.status]: false,
        [DietTypeProperties.isPublic]: false,
        [DietTypeProperties.avatar]: null,
        [DietTypeProperties.picto]: null,
        [DietTypeProperties.checkup]: '',
        [DietTypeProperties.isNew]: false,
        [DietTypeProperties.mostPopular]: false,
    },
    validationSchema: yup.object({
        [DietTypeProperties.name]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Type de menu',
        listInputs: [
            {
                label: 'Nom',
                property: DietTypeProperties.name,
            },
            {
                label: 'Description',
                property: DietTypeProperties.description,
            },
            {
                label: 'Calories',
                property: DietTypeProperties.calories,
                type: InputType.integer,
            },
            {
                label: 'Kilos par mois',
                property: DietTypeProperties.kilosPerMonth,
                type: InputType.float,
            },
            {
                label: 'Publié ?',
                property: DietTypeProperties.status,
                type: InputType.boolean,
            },
            {
                label: 'Est Publique ?',
                property: DietTypeProperties.isPublic,
                type: InputType.boolean,
            },
            {
                label: 'Avatar',
                property: DietTypeProperties.avatar,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Picto',
                property: DietTypeProperties.picto,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Checkup',
                property: DietTypeProperties.checkup,
            },
            {
                label: 'Nouveau ?',
                property: DietTypeProperties.isNew,
                type: InputType.boolean,
            },
            {
                label: 'Le plus populaire ?',
                property: DietTypeProperties.mostPopular,
                type: InputType.boolean,
            },
        ],
    },

    configList: {
        label: 'Types de menu',
        listProperties: [
            {
                label: '#',
                property: DietTypeProperties.id,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Image',
                property: DietTypeProperties.avatar,
                transform: ImageConverter.display,
                styles: {
                    width: '15%',
                },
            },
            {
                label: 'Nom',
                property: DietTypeProperties.name,
                sortable: true,
            },
            {
                label: 'Description',
                property: DietTypeProperties.description,
            },
            {
                label: 'Publié ?',
                property: DietTypeProperties.status,
                transform: BooleanConverter.display,
            },
            {
                label: 'Public ?',
                property: DietTypeProperties.isPublic,
                transform: BooleanConverter.display,
            },
            {
                label: 'Calories',
                property: DietTypeProperties.calories,
            },
            {
                label: 'Kilos par mois',
                property: DietTypeProperties.kilosPerMonth,
            },
        ],
        allowAdd: true,
    },
};

export default DietType;
export { DietTypeProperties };
