/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import PaginationType from '../types/PaginationType';
import { Dispatch, SetStateAction } from 'react';
import { ConfigType } from '../types/ConfigType';
import { getLocalAccessToken, getLocalRefreshToken } from './ApiManager';

const numberPattern = /\d+/g;

class ApiService {
    config: ConfigType;
    token: string;
    refreshToken: string;
    loggedIn: boolean;
    status: number | undefined;
    isLoadingCallback: Dispatch<SetStateAction<boolean>>;
    loggedInCallback: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationType | null;
    countItems: number;
    retryFlag: boolean;

    constructor() {
        this.config = {
            allowedRoles: [],
            navigation: [],
            screens: [],
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            getCustomRouteList: (_) => [],
            clients: {},
        };
        this.token = '';
        this.refreshToken = '';
        this.loggedIn = false;
        this.status = undefined;
        this.retryFlag = false;
        this.isLoadingCallback = () => {
            return;
        };
        this.loggedInCallback = () => {
            return;
        };
        this.pagination = null;
        this.countItems = 0;
    }

    loadConfig = (config: ConfigType): void => {
        this.config = config;
        this.token = getLocalAccessToken();
        this.refreshToken = getLocalRefreshToken();
        this.loggedIn = this.token !== '';
        this.status = undefined;
        this.pagination = null;
        this.countItems = 0;
        if (this.loggedIn) {
            this.setAuthHeader();
            this.setRefreshTokenInterceptor();
        }
    };

    setHttpCode = (code: number | undefined): void => {
        this.status = code;
    };

    setPagination = (pagination: PaginationType): void => {
        this.pagination = pagination;
        this.pagination.max = this.setMaxPagination(pagination);
    };

    setMaxPagination = (pagination: PaginationType): number => {
        const match = pagination['hydra:last']?.match(numberPattern);
        if (Array.isArray(match)) {
            return Number(match[0]);
        }
        return 1;
    };

    getAll = async (type: string, filters = {}, options = {}, service = 'ck'): Promise<AxiosResponse | null> => {
        const http = this.config.clients[service];

        const params = {
            ...filters,
            ...options,
        };
        this.isLoadingCallback(true);

        return http
            .get(`/${type}`, { params })
            .then((data: AxiosResponse): Promise<AxiosResponse | null> => {
                this.pagination = null;
                if (data.data.hasOwnProperty('hydra:view')) {
                    this.setPagination(data.data['hydra:view']);
                } else if (data?.status === 401) {
                    this.loggedIn = false;
                    this.token = '';
                    this.refreshToken = '';
                    this.loggedInCallback(this.loggedIn);
                }
                this.setHttpCode(data?.status);
                this.isLoadingCallback(false);
                return Promise.resolve(data);
            })
            .catch((error: AxiosError) => {
                this.setHttpCode(error.response?.status);
                this.isLoadingCallback(false);
                return Promise.reject(error);
            });
    };

    get = (type: string, id: number | string, service = 'ck'): Promise<AxiosResponse | null> => {
        const http = this.config.clients[service];
        this.isLoadingCallback(true);

        return http
            .get(`/${type}/${id}`)
            .then((data: AxiosResponse): Promise<AxiosResponse | null> => {
                if (data?.status === 401) {
                    this.loggedIn = false;
                    this.token = '';
                    this.refreshToken = '';
                    this.loggedInCallback(this.loggedIn);
                }
                this.setHttpCode(data?.status);
                this.isLoadingCallback(false);
                return Promise.resolve(data);
            })
            .catch((error: AxiosError) => {
                this.setHttpCode(error.response?.status);
                this.isLoadingCallback(false);
                return Promise.reject(error);
            });
    };

    create = (type: string, data: unknown, service = 'ck'): AxiosPromise => {
        const http = this.config.clients[service];
        this.isLoadingCallback(true);

        return http
            .post(`/${type}`, data)
            .then((data: AxiosResponse): AxiosResponse => {
                this.isLoadingCallback(false);
                return data;
            })
            .catch((error: AxiosError) => {
                this.setHttpCode(error.response?.status);
                this.isLoadingCallback(false);
                return Promise.reject(error);
            });
    };

    upload = (type: string, file: File, service = 'ck'): AxiosPromise => {
        const http = this.config.clients[service];
        this.isLoadingCallback(true);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        const formData = new FormData();
        formData.append('file', file);

        return http
            .post(`/${type}`, formData, { headers })
            .then((data: AxiosResponse): AxiosResponse => {
                this.isLoadingCallback(false);
                return data;
            })
            .catch((error: AxiosError) => {
                this.setHttpCode(error.response?.status);
                this.isLoadingCallback(false);
                return Promise.reject(error);
            });
    };

    update = (type: string, id: number | string, data: unknown, service = 'ck'): AxiosPromise => {
        const http = this.config.clients[service];
        this.isLoadingCallback(true);

        return http.put(`/${type}/${id}`, data).then((data: AxiosResponse): AxiosResponse => {
            this.isLoadingCallback(false);
            return data;
        });
    };

    remove = (type: string, id: number | string, service = 'ck'): AxiosPromise => {
        const http = this.config.clients[service];
        this.isLoadingCallback(true);

        return http.delete(`/${type}/${id}`).then((data: AxiosResponse): AxiosResponse => {
            this.isLoadingCallback(false);
            return data;
        });
    };

    login = async (username: string, password: string): Promise<any> => {
        const http = this.config.clients['ck'];
        this.isLoadingCallback(true);

        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');

        return http
            .post(`${process.env.REACT_APP_AUTH_URL}/login_check`, {
                username: username,
                password: password,
            })
            .then(async (data: AxiosResponse) => {
                if (!data.data.user.roles.some((r: string) => this.config.allowedRoles.includes(r))) {
                    return {
                        logged: false,
                    };
                }
                const token = data.data.token;
                const refreshToken = data.data.refresh_token;
                this.loggedIn = true;
                this.token = token;
                this.refreshToken = refreshToken;
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);
                this.setAuthHeader();
                this.isLoadingCallback(false);
                this.loggedInCallback(this.loggedIn);
                return {
                    logged: this.loggedIn,
                    userInfos: data.data.user,
                    token: token,
                };
            })
            .catch((error: AxiosError) => {
                this.isLoadingCallback(false);
                this.loggedInCallback(false);
                return Promise.reject(error);
            });
    };

    impersonate = (id: string | number): AxiosPromise => {
        const http = this.config.clients['ck'];

        return http.get(`/users/${id}/impersonate`).then((res) => res);
    };

    refreshExpiredToken = async (): Promise<any> => {
        const http = this.config.clients['ck'];

        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');

        return http
            .post(`${process.env.REACT_APP_AUTH_URL}/token/refresh`, {
                refresh_token: this.refreshToken,
            })
            .then(async (data: AxiosResponse) => {
                if (data.status == 401) {
                    throw 'Expired Refresh Token';
                }
                this.retryFlag = false;
                const token = data.data.token;
                const refreshToken = data.data.refresh_token;
                this.token = token;
                this.refreshToken = refreshToken;
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);
            })
            .catch((error: AxiosError) => {
                console.log('error : ', error);
                this.loggedIn = false;
                this.token = '';
                this.refreshToken = '';
                this.loggedInCallback(false);
                this.isLoadingCallback(false);
                return Promise.reject(error);
            });
    };

    setRefreshTokenInterceptor = async (): Promise<any> => {
        let k: string;

        for (k in this.config.clients) {
            const client = this.config.clients[k];

            client.interceptors.response.use(
                (response) => {
                    if (process.env.NODE_ENV == 'development') {
                        // console.log(response);
                    }
                    return response;
                },
                async (error) => {
                    const originalRequest = error.config;
                    if (k === 'ck' && error.response?.status === 401 && !this.retryFlag) {
                        this.retryFlag = true;
                        await this.refreshExpiredToken();
                        // The setAuthHeader interceptor will catch the original request and add the new token to it during retry
                        return client(originalRequest);
                    }
                    if (process.env.NODE_ENV == 'development') {
                        console.log(error);
                    }
                    if (error.response === undefined) {
                        return Promise.reject(error);
                    } else {
                        return error.response;
                    }
                },
            );
        }
    };

    setAuthHeader = async (): Promise<any> => {
        Object.values(this.config.clients).forEach((client) => {
            client.interceptors.request.use(async (config: any) => {
                if (process.env.NODE_ENV == 'development') {
                    //console.log(config);
                }
                config.headers.Authorization = `Bearer ${this.token}`;
                return config;
            });
        });
    };

    setIsLoadingCallback = (callback: Dispatch<SetStateAction<boolean>>): void => {
        this.isLoadingCallback = callback;
    };

    setLoggedInCallback = (callback: Dispatch<SetStateAction<boolean>>): void => {
        this.loggedInCallback = callback;
    };

    hasClient = (service: string): boolean => {
        return this.config.clients.hasOwnProperty(service);
    };
}

export default ApiService;
