import { Avatar, Chip } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import FaceIcon from '@mui/icons-material/Face';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { PAYMENT_PROVIDER, PAYMENT_PROVIDER_COLOR, PAYMENT_PROVIDER_LOGO } from '../../constants';

const getAvatar = (label: string, src: string, alt: string) => {
    return (
        <Avatar alt={alt} src={src}>
            {label}
        </Avatar>
    );
};

const getIcon = (elt: string): any => {
    switch (elt) {
        case PAYMENT_PROVIDER.APPLE:
            return {
                icon: <AppleIcon />,
            };
            break;
        case PAYMENT_PROVIDER.GOOGLE:
            return {
                icon: <AndroidIcon />,
            };
            break;
        case PAYMENT_PROVIDER.STRIPE:
            return {
                avatar: getAvatar('S', PAYMENT_PROVIDER_LOGO.STRIPE, PAYMENT_PROVIDER.STRIPE),
            };
            break;
        case PAYMENT_PROVIDER.PAYBOX:
            return {
                icon: <LocalAtmIcon />,
            };
            break;
        case PAYMENT_PROVIDER.MANUAL:
            return {
                icon: <FaceIcon />,
            };
            break;
    }
    return {
        icon: <DeviceUnknownIcon />,
    };
};

const UserListPaymentCell = (element: any): JSX.Element => {
    const finalLabel = element;
    const sx = {};
    const icon = getIcon(element);
    return (
        <Chip
            {...icon}
            key={finalLabel}
            size="small"
            label={finalLabel}
            color="primary"
            sx={{
                ...sx,
                backgroundColor: PAYMENT_PROVIDER_COLOR[element],
                color: 'white',
                marginRight: '5px',
                marginBottom: '5px',
                fontWeight: 'bold',
                fontSize: '0.75rem',
            }}
        />
    );
};

export default UserListPaymentCell;
