import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const IngredientCategoryProperties = {
    id: 'id',
    title: 'title',
    totalIngredients: 'totalIngredients',
};

const IngredientCategory: ResourceType = {
    resourcePath: 'ingredient_categories',
    placeHolder: {
        [IngredientCategoryProperties.title]: '',
        [IngredientCategoryProperties.totalIngredients]: 0,
    },

    validationSchema: yup.object({
        [IngredientCategoryProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: "Catégorie d'ingrédients",
        listInputs: [
            {
                label: 'Nom',
                property: IngredientCategoryProperties.title,
            },
        ],
    },

    configList: {
        label: "Catégories d'ingrédients",
        listProperties: [
            {
                label: '#',
                property: IngredientCategoryProperties.id,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Catégorie',
                property: IngredientCategoryProperties.title,
                sortable: true,
            },
            {
                label: "Nombre d'ingrédients",
                property: IngredientCategoryProperties.totalIngredients,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Catégorie',
                property: IngredientCategoryProperties.title,
                type: InputType.text,
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default IngredientCategory;
export { IngredientCategoryProperties };
