/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip } from '@mui/material';

class TagConverter {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static display = (element: any, label?: string, sx?: any): JSX.Element => {
        const finalLabel = label ? element[label] : element;
        return (
            <Chip
                key={finalLabel}
                size="small"
                label={finalLabel}
                sx={{
                    ...sx,
                    marginRight: '5px',
                    marginBottom: '5px',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                }}
            />
        );
    };
}

export default TagConverter;
