import * as yup from 'yup';
import {
    InputType,
    ResourceType,
    ImageConverter,
    TagConverter,
    BooleanConverter,
    DateConverter,
    SelectRenderType,
} from 'm6BoBuilder';
import SubformAssetObject from 'components/form/subform/SubformAssetObject';
import PrivateArticleCategory, { PrivateArticleCategoryProperties } from './PrivateArticleCategory';
import ChoiceConverter from '../../m6BoBuilder/converters/ChoiceConverter';
import ArticleAuthor, { ArticleAuthorProperties } from './ArticleAuthor';
import { PUBLISHED_STATUS_OPTIONS as STATUS_OPTIONS } from '../../constants';

const PrivateArticleProperties = {
    title: 'title',
    publicationDate: 'publicationDate',
    status: 'status',
    category: 'category',
    body: 'body',
    video: 'video',
    titleMedia: 'titleMedia',
    pdf: 'pdf',
    type: 'type',
    isHighlighted: 'isHighlighted',
    isPermanent: 'isPermanent',
    author: 'author',
};

const PrivateArticle: ResourceType = {
    resourcePath: 'bo-private_articles',
    placeHolder: {
        [PrivateArticleProperties.title]: '',
        [PrivateArticleProperties.category]: [],
        [PrivateArticleProperties.body]: '',
        [PrivateArticleProperties.status]: 0,
        [PrivateArticleProperties.video]: '',
        [PrivateArticleProperties.titleMedia]: null,
        [PrivateArticleProperties.pdf]: null,
        [PrivateArticleProperties.publicationDate]: new Date(),
        [PrivateArticleProperties.type]: 0,
        [PrivateArticleProperties.isHighlighted]: false,
        [PrivateArticleProperties.isPermanent]: false,
        [PrivateArticleProperties.author]: null,
    },
    validationSchema: yup.object({
        [PrivateArticleProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [PrivateArticleProperties.pdf]: yup.object().nullable(),
    }),
    configForm: {
        label: 'Article privé',
        listInputs: [
            {
                label: 'Titre',
                property: PrivateArticleProperties.title,
            },
            {
                label: 'Catégories',
                property: PrivateArticleProperties.category,
                type: InputType.entity,
                options: {
                    resource: PrivateArticleCategory.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Auteur(e)',
                property: PrivateArticleProperties.author,
                type: InputType.entity,
                options: {
                    resource: ArticleAuthor.resourcePath,
                    label: ArticleAuthorProperties.displayName,
                    multiple: false,
                },
            },
            {
                label: 'Date de publication',
                property: PrivateArticleProperties.publicationDate,
                type: InputType.datetime,
            },
            {
                label: 'Statut',
                property: PrivateArticleProperties.status,
                type: InputType.select,
                options: {
                    choices: STATUS_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Contenu',
                property: PrivateArticleProperties.body,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Type',
                property: PrivateArticleProperties.type,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 0, label: 'Article' },
                        { value: 1, label: 'Vidéo' },
                        { value: 2, label: 'PDF' },
                    ],
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Image en-tête',
                property: PrivateArticleProperties.titleMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Id Youtube',
                property: PrivateArticleProperties.video,
            },
            {
                label: 'PDF',
                property: PrivateArticleProperties.pdf,
                type: InputType.subform,
                options: {
                    subform: SubformAssetObject,
                    multiple: false,
                },
            },
            {
                label: 'Mettre en avant',
                property: PrivateArticleProperties.isHighlighted,
                type: InputType.boolean,
            },
            {
                label: 'Contenu incontournable',
                property: PrivateArticleProperties.isPermanent,
                type: InputType.boolean,
            },
        ],
    },
    configList: {
        label: 'Articles privés',
        listProperties: [
            {
                label: 'Nom',
                property: PrivateArticleProperties.title,
                sortable: true,
            },
            {
                label: 'Auteur(e)',
                property: `${PrivateArticleProperties.author}.${ArticleAuthorProperties.displayName}`,
                sortable: true,
            },
            {
                label: 'Image',
                property: PrivateArticleProperties.titleMedia,
                transform: ImageConverter.display,
            },
            {
                label: 'Catégories',
                property: PrivateArticleProperties.category,
                transform: (elements: []) => elements.map((elt) => TagConverter.display(elt, 'title')),
            },
            {
                label: 'Type',
                property: PrivateArticleProperties.type,
                transform: (element: number) =>
                    ChoiceConverter.display(element, [
                        { value: 0, label: 'Article' },
                        { value: 1, label: 'Vidéo' },
                        { value: 2, label: 'PDF' },
                    ]),
            },
            {
                label: 'Publié',
                property: PrivateArticleProperties.status,
                transform: (element: string) => BooleanConverter.display(parseInt(element) > 0 ? true : false),
                sortable: true,
            },
            {
                label: 'Date de publication',
                property: PrivateArticleProperties.publicationDate,
                transform: DateConverter.display,
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: PrivateArticleProperties.title,
            },
            {
                label: 'Catégorie',
                property: `${PrivateArticleProperties.category}.id`,
                type: InputType.entity,
                options: {
                    label: PrivateArticleCategoryProperties.title,
                    resource: PrivateArticleCategory.resourcePath,
                },
            },
            {
                label: 'Statut',
                property: PrivateArticleProperties.status,
                type: InputType.select,
                options: {
                    choices: STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Type',
                property: PrivateArticleProperties.type,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 0, label: 'Article' },
                        { value: 1, label: 'Vidéo' },
                        { value: 2, label: 'PDF' },
                    ],
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Auteur(e)',
                property: `${PrivateArticleProperties.author}.id`,
                type: InputType.entity,
                options: {
                    label: ArticleAuthorProperties.displayName,
                    resource: ArticleAuthor.resourcePath,
                    resourceIdentifier: 'slug',
                },
            },
        ],
        defaultOrder: {
            property: PrivateArticleProperties.publicationDate,
            order: 'desc',
        },
    },
    actions: {
        delete: true,
    },
};

export default PrivateArticle;
export { PrivateArticleProperties };
