/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { IconButton, Grid } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import {
    ApiContext,
    FormEntity,
    SubFormType,
    FormElementType,
    flashMessage,
    flashType,
    ImageConverter,
} from 'm6BoBuilder';
import { getObjectProperty } from 'm6BoBuilder/utils/getObjectProperty';
import { FormikValues } from 'formik';
import Recipe from 'config/resources/Recipe';
import { EventRecipeRecipeProperties } from 'config/resources/EventRecipeRecipe';
import ApiService from '../../../m6BoBuilder/services/ApiService';
import { AxiosError } from 'axios';

type RecipeType = {
    id: number;
    picture: Record<string, undefined>;
    rating: number;
};

const configRecipe: FormElementType = {
    label: 'Recette',
    property: EventRecipeRecipeProperties.recipe,
    options: {
        resource: Recipe.resourcePath,
    },
};

const SubformEventRecipeRecipe = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const apiService: ApiService = useContext(ApiContext);
    const [recipeData, setRecipeData] = useState<RecipeType | null>(null);
    const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? {
            weight: null,
            recipe: null,
        },
    );

    useEffect(() => {
        if (entity[configLine.property][position] !== undefined) {
            getRecipeData(entity[configLine.property][position]);
        }
    }, []);

    const getRecipeData = ({ recipe = null }: any) => {
        if (null === recipe || recipe === undefined) {
            return;
        }
        const { id } = typeof recipe === 'string' ? { id: recipe.split('/').slice(-1)[0] } : recipe;
        if (null === id || id === undefined) {
            return;
        }
        apiService
            .get(Recipe.resourcePath, id)
            .then((data) => {
                if (data !== null) {
                    setRecipeData(data.data);
                }
            })
            .catch((error: AxiosError) => {
                flashMessage(error.response?.data['hydra:description'] ?? error.message, flashType.ERROR);
            });
    };

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        entity[configLine.property][position] = updatedSubEntity;
        setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);
        getRecipeData(updatedSubEntity as any);
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={1} sx={{ marginTop: '20px' }}>
                {position + 1}
            </Grid>
            <Grid item xs={2}>
                {recipeData ? getObjectProperty(recipeData, 'picture', (ImageConverter.display as any) ?? null) : ''}
            </Grid>
            <Grid item xs={6}>
                <FormEntity entity={subEntity} updateEntity={updateSubEntity} configLine={configRecipe} keepEntity />
            </Grid>
            <Grid item xs={1} sx={{ marginTop: '20px' }}>
                {recipeData ? `${recipeData.rating}/5` : ''}
            </Grid>
            <Grid item xs={1} sx={{ marginTop: '10px' }}>
                {recipeData && (
                    <IconButton
                        aria-label="open"
                        color="primary"
                        size="small"
                        href={`${process.env.REACT_APP_WWW_URL}/mes-recettes/recette?id=${recipeData.id}`}
                        target="_blank"
                    >
                        <OpenInNew />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
};

export default SubformEventRecipeRecipe;
