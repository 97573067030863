import * as yup from 'yup';
import { DateConverter, ResourceType, InputType, ImageConverter } from 'm6BoBuilder';

const PrivatePromoPopinProperties = {
    id: 'id',
    title: 'title',
    url: 'url',
    target: 'target',
    label: 'label',
    media: 'media',
    startDate: 'startDate',
    endDate: 'endDate',
};

const PrivatePromoPopin: ResourceType = {
    resourcePath: 'private_promo_popins',
    placeHolder: {
        [PrivatePromoPopinProperties.id]: 0,
        [PrivatePromoPopinProperties.title]: '',
        [PrivatePromoPopinProperties.url]: '',
        [PrivatePromoPopinProperties.target]: undefined,
        [PrivatePromoPopinProperties.label]: '',
        [PrivatePromoPopinProperties.media]: undefined,
        [PrivatePromoPopinProperties.startDate]: undefined,
        [PrivatePromoPopinProperties.endDate]: undefined,
    },
    validationSchema: yup.object({
        [PrivatePromoPopinProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [PrivatePromoPopinProperties.startDate]: yup.date().required('Ce champ est obligatoire'),
        [PrivatePromoPopinProperties.endDate]: yup.date().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Block promotionnel privé',
        listInputs: [
            {
                label: 'Titre',
                property: PrivatePromoPopinProperties.title,
            },
            {
                label: 'Bouton : Label',
                property: PrivatePromoPopinProperties.label,
            },
            {
                label: 'Bouton : Url',
                property: PrivatePromoPopinProperties.url,
            },
            {
                label: 'Bouton : Ouvrable dans un nouvel onglet',
                property: PrivatePromoPopinProperties.target,
                type: InputType.boolean,
            },
            {
                label: 'Média',
                property: PrivatePromoPopinProperties.media,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Date de début',
                property: PrivatePromoPopinProperties.startDate,
                type: InputType.date,
            },
            {
                label: 'Date de fin',
                property: PrivatePromoPopinProperties.endDate,
                type: InputType.date,
            },
        ],
    },

    configList: {
        label: 'Block promotionnel privée',
        listProperties: [
            {
                label: 'ID',
                property: PrivatePromoPopinProperties.id,
                sortable: true,
            },
            {
                label: 'Image',
                property: PrivatePromoPopinProperties.media,
                transform: ImageConverter.display,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Titre',
                property: PrivatePromoPopinProperties.title,
            },
            {
                label: 'Url',
                property: PrivatePromoPopinProperties.url,
            },
            {
                label: 'Date de début',
                property: PrivatePromoPopinProperties.startDate,
                transform: DateConverter.display,
            },
            {
                label: 'Date de fin',
                property: PrivatePromoPopinProperties.endDate,
                transform: DateConverter.display,
            },
        ],
        filters: [
            {
                label: 'Titre',
                property: PrivatePromoPopinProperties.title,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default PrivatePromoPopin;
export { PrivatePromoPopinProperties };
