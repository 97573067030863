/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import Paginator from '../m6BoBuilder/components/Paginator';
import CustomLoader from '../m6BoBuilder/components/CustomLoader';
import { useTheme } from '@mui/material/styles';
import { useGetUserLogs } from '../services/useQueries';

type UserDetailCardPropsType = {
    id: number | string;
};

const CATEGORY_LABELS: Array<string> = ['General', 'Compte', 'Paiement', 'Abonnement', 'Programme'];
const STATUS_LABELS: Array<string> = ['INFO', 'PRIMARY', 'SUCCESS', 'WARNING', 'DANGER'];

const UserLogCard = ({ id }: UserDetailCardPropsType): JSX.Element => {
    const theme = useTheme();
    const [maxPage, setMaxPage] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filters, setFilters] = useState<{ [key: string]: any }>({
        'order[date]': 'desc',
        itemsPerPage: 25,
    });

    // QUERIES
    const { isFetching, data: { list: logs, totalItems } = { list: [], totalItems: 0 } } = useGetUserLogs(id, {
        ...filters,
        page: currentPage,
    });

    useEffect((): void => {
        setMaxPage(Math.ceil(totalItems / filters.itemsPerPage));
    }, [totalItems]);

    const updateCurrentPage = (selectedPage: number): void => {
        setCurrentPage(selectedPage);
    };

    const handleChangeCategory = (event: SelectChangeEvent): void => {
        setCurrentPage(1);
        setFilters({ ...filters, category: event.target.value });
    };

    const handleChangeStatus = (event: SelectChangeEvent): void => {
        setCurrentPage(1);
        setFilters({ ...filters, status: event.target.value });
    };

    const getMenuItemColorByValue = (value: string) => {
        switch (value) {
            case 'DANGER':
                return theme.palette.error.dark;
            case 'INFO':
                return theme.palette.info.dark;
            case 'WARNING':
                return theme.palette.warning.dark;
            case 'SUCCESS':
                return theme.palette.success.dark;
        }
    };

    return (
        <>
            <Typography component="h3" variant="h3" sx={{ mt: 4, mb: 4 }}>
                Logs utilisateur
            </Typography>
            <Card>
                <FormControl size={'small'} sx={{ minWidth: 120, marginTop: 3, marginLeft: 5 }}>
                    <InputLabel>Catégorie</InputLabel>
                    <Select
                        autoWidth
                        value={filters.category}
                        name={'Catégorie'}
                        label={'Catégorie'}
                        onChange={handleChangeCategory}
                    >
                        <MenuItem value={''}>Toutes</MenuItem>
                        {CATEGORY_LABELS.map((value: string, index: number): JSX.Element => {
                            return (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl size={'small'} sx={{ minWidth: 120, marginTop: 3, marginLeft: 5 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        autoWidth
                        value={filters.status}
                        name={'Status'}
                        label={'Status'}
                        onChange={handleChangeStatus}
                    >
                        <MenuItem value={''}>Tous</MenuItem>
                        {STATUS_LABELS.map((value: string, index: number): JSX.Element => {
                            return (
                                <MenuItem key={index} value={value} sx={{ color: getMenuItemColorByValue(value) }}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <CardContent sx={{ height: 200, overflow: 'auto' }}>
                    {isFetching || !logs ? (
                        <Grid container justifyContent="center" alignItems="center">
                            <CustomLoader />
                        </Grid>
                    ) : logs.length > 0 ? (
                        <ul>
                            {logs.map((log: any, key: any) => (
                                <li key={key} style={{ color: getMenuItemColorByValue(log.status) }}>
                                    {log.formattedmessage}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <ul>
                            <li>Aucun élément</li>
                        </ul>
                    )}
                    {!isFetching && (
                        <Paginator maxPage={maxPage} currentPage={currentPage} updateCurrentPage={updateCurrentPage} />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default UserLogCard;
