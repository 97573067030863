// material-ui
import { /*Button,*/ CardContent, CardMedia, Grid, /*Stack,*/ Typography } from '@mui/material';

// project import
import MainCard from '../../m6BoBuilder/berry/ui-component/cards/MainCard';
import { KeyedObject } from 'm6BoBuilder/types';

// assets
//import { Preview as PreviewIcon } from '@mui/icons-material';
import { RecipeType } from './types';

export interface RecipeCardProps extends KeyedObject {
    recipe: RecipeType;
    onClick: (recipe: RecipeType) => void;
}

// ==============================|| RECIPE CARD ||============================== //

const RecipeCard = ({ recipe, onClick }: RecipeCardProps) => {
    const { title, kcal, picture } = recipe;

    /*const previouVariant = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };*/

    return (
        <>
            <MainCard
                content={false}
                boxShadow
                sx={{
                    margin: 1,
                    width: 220,
                    height: 220,
                    '&:hover': {
                        transform: 'scale3d(1.02, 1.02, 1)',
                        transition: 'all .2s ease-in-out',
                    },
                }}
            >
                <CardMedia
                    sx={{ height: 120 }}
                    image={`${process.env.REACT_APP_API_URL}${picture?.medium}`}
                    title={`${title}`}
                    onClick={() => {
                        onClick(recipe);
                    }}
                />
                <CardContent sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ textDecoration: 'none' }}>
                                {`${title} (${kcal} kcal)`}
                            </Typography>
                        </Grid>

                        {/*<Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Button variant="contained" sx={{ minWidth: 0 }} onClick={previouVariant}>
                                        <PreviewIcon fontSize="small" />
                                    </Button>
                                </Stack>
                            </Grid>*/}
                    </Grid>
                </CardContent>
            </MainCard>
        </>
    );
};

export default RecipeCard;
