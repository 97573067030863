/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResourceType, InputType } from 'm6BoBuilder';
import * as yup from 'yup';
import PromoCode, { PromoCodeProperties } from './PromoCode';

const ParameterProperties = {
    id: 'id',
    promoCode: 'promoCode',
};

const Parameter: ResourceType = {
    resourcePath: 'parameters',
    placeHolder: {
        [ParameterProperties.promoCode]: null,
    },
    validationSchema: yup.object({}),
    configForm: {
        label: 'Parametres',
        listInputs: [
            {
                label: 'Code promo actif',
                property: ParameterProperties.promoCode,
                type: InputType.entity,
                options: {
                    resource: PromoCode.resourcePath,
                    label: PromoCodeProperties.code,
                    multiple: false,
                },
            },
        ],
    },
    configList: {
        label: 'Parametres',
        listProperties: [],
        allowAdd: false,
    },
    actions: {
        delete: false,
    },
};

export type ParameterType = {
    id?: number;
    promoCode: any;
};

export default Parameter;
export { ParameterProperties };
