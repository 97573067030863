import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';
import { GoalProperties } from './Goal';
import { LevelProperties } from './Level';
import { BodyPartsProperties } from './BodyParts';

const UserYGProperties = {
    id: 'id',
    goals: 'goals',
    level: 'level',
    bodyParts: 'bodyParts',
};

const UserYG: ResourceType = {
    resourcePath: 'user_details',
    placeHolder: {
        [UserYGProperties.goals]: [],
        [UserYGProperties.level]: null,
        [UserYGProperties.bodyParts]: [],
    },
    validationSchema: yup.object({}),
    service: 'yg',
    configForm: {
        label: 'Infos YG',
        listInputs: [
            {
                label: 'Niveau',
                property: UserYGProperties.level,
                type: InputType.entity,
                options: {
                    resource: 'levels',
                    multiple: false,
                    label: LevelProperties.title,
                },
            },
            {
                label: 'Objectif',
                property: UserYGProperties.goals,
                type: InputType.entity,
                options: {
                    resource: 'goals',
                    multiple: true,
                    label: GoalProperties.title,
                },
            },
            {
                label: 'Zones du corps',
                property: UserYGProperties.bodyParts,
                type: InputType.entity,
                options: {
                    resource: 'body_parts',
                    multiple: true,
                    label: BodyPartsProperties.title,
                },
            },
        ],
    },
    configList: {
        label: '',
        listProperties: [],
        allowAdd: false,
    },
};

export default UserYG;
export { UserYGProperties };
