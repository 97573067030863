/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateConverter, ResourceType, InputType, SelectRenderType, ApiService } from 'm6BoBuilder';
import * as yup from 'yup';
import { UserProperties } from './User';
import Recipe, { RecipeProperties } from './Recipe';
import StringConverter from 'm6BoBuilder/converters/StringConverter';
import TagConverter from 'm6BoBuilder/converters/TagConverter';
import { useTheme } from '@mui/material/styles';
import { COMMENT_STATUS_OPTIONS as STATUS_CHOICES } from '../../constants';
import CommentRecipeForm from 'components/form/CommentRecipeForm';

const CommentRecipeProperties = {
    id: 'id',
    title: 'title',
    recipe: 'recipe',
    score: 'score',
    text: 'text',
    status: 'status',
    user: 'user',
    createdAt: 'createdAt',
    comments: 'comments',
};

const getChipColor = (element: any): any => {
    const theme = useTheme();
    switch (element.value) {
        case 1:
            return {
                color: theme.palette.success.dark,
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
            };
        case 2:
            return {
                color: theme.palette.warning.dark,
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
            };
        default:
            return {
                color: theme.palette.error.dark,
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.error.light,
            };
    }
};

const CommentRecipe: ResourceType = {
    resourcePath: 'bo-comment_recipes',
    placeHolder: {
        [CommentRecipeProperties.id]: null,
        [CommentRecipeProperties.text]: '',
        [CommentRecipeProperties.status]: 2,
        [CommentRecipeProperties.recipe]: null,
        [CommentRecipeProperties.comments]: [],
    },

    validationSchema: yup.object({
        [CommentRecipeProperties.text]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Commentaire',
        listInputs: [
            {
                label: 'Recette',
                property: `${CommentRecipeProperties.recipe}.${RecipeProperties.title}`,
                options: {
                    readOnly: true,
                },
                type: InputType.text,
            },
            {
                label: 'Commentaire',
                property: CommentRecipeProperties.text,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Status',
                property: CommentRecipeProperties.status,
                type: InputType.select,
                options: {
                    choices: STATUS_CHOICES,
                    render: SelectRenderType.select,
                },
            },
        ],
        editForm: CommentRecipeForm,
    },

    configList: {
        label: 'Commentaires',
        selectable: true,
        itemsPerPage: 50,
        massActions: [
            {
                label: 'Tout valider',
                action: async (entity: any, apiService: ApiService): Promise<void> => {
                    entity.status = 1;
                    await apiService.update('bo-comment_recipes', entity.id, entity);
                },
            },
            {
                label: 'Tout non valide',
                action: async (entity: any, apiService: ApiService): Promise<void> => {
                    entity.status = 0;
                    await apiService.update('bo-comment_recipes', entity.id, entity);
                },
            },
            {
                label: 'Tout supprimer',
                action: async (entity: any, apiService: ApiService): Promise<void> => {
                    await apiService.remove('bo-comment_recipes', entity.id);
                },
            },
        ],
        listProperties: [
            {
                label: '#',
                property: CommentRecipeProperties.id,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Crée le',
                property: CommentRecipeProperties.createdAt,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Commentaire',
                property: CommentRecipeProperties.text,
                transform: StringConverter.stripTags,
            },
            {
                label: 'Statut',
                property: CommentRecipeProperties.status,
                transform: (value: number) => {
                    const element = STATUS_CHOICES.find((element) => value == element.value);
                    return TagConverter.display(element ?? '-', 'label', getChipColor(element));
                },
                sortable: true,
            },
            {
                label: 'Recette',
                property: `${CommentRecipeProperties.recipe}.${RecipeProperties.title}`,
                sortable: true,
            },
            {
                label: 'Auteur',
                property: `${CommentRecipeProperties.user}.${UserProperties.fullname}`,
                sortable: true,
            },
            {
                label: 'Score',
                property: CommentRecipeProperties.score,
                sortable: true,
            },
        ],
        allowAdd: false,
        filters: [
            {
                label: 'Commentaire',
                property: CommentRecipeProperties.text,
            },
            {
                label: 'Statut',
                property: CommentRecipeProperties.status,
                type: InputType.select,
                options: {
                    choices: STATUS_CHOICES,
                    render: SelectRenderType.select,
                    default: 2,
                },
            },
            {
                label: 'Recette',
                property: `${CommentRecipeProperties.recipe}.id`,
                type: InputType.entity,
                options: {
                    label: RecipeProperties.title,
                    resource: Recipe.resourcePath,
                },
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default CommentRecipe;
export { CommentRecipeProperties };
