import { SubFormType } from './SubFormType';
import { EditFormType } from './EditFormType';
import { GridSize } from '@mui/material';
import { RessourceMode } from '../ResourceType';

type ConfigFormType = {
    /**
     * Default label on top of form page
     */
    label: string;

    /**
     * Allow to override DefaultForm and use custom edit form
     */
    editForm?: ({ entity, config, handleEntityUpdate }: EditFormType) => JSX.Element;

    /**
     * Allow to modify entity properties before submit
     */
    beforeSubmit?: (entity: Record<string, unknown>, context: RessourceMode) => Record<string, unknown>;

    /**
     * Allow custom action after submit
     */
    afterSubmit?: (entity: Record<string, unknown>, context: RessourceMode) => boolean;

    /**
     * List of config for each field
     * @see FormElementType
     */
    listInputs: FormElementType[];
};

/**
 * List of available field types callable in config form
 */
enum InputType {
    text = 'text',
    password = 'password',
    integer = 'integer',
    float = 'float',
    boolean = 'boolean',
    date = 'date',
    datetime = 'datetime',
    image = 'image',
    entity = 'entity',
    select = 'select',
    subform = 'subform',
    file = 'file',
    colorPicker = 'colorPicker',
}

/**
 * List of available widgets for InputType.select
 * @see InputType.select
 */
enum SelectRenderType {
    select = 'select',
    checkbox = 'checkbox',
    radio = 'radio',
}

/**
 * Describe form field rendering and management
 */
type FormElementType = {
    /**
     * Field label
     */
    label: string;
    /**
     * Entity property managed by this field
     */
    property: string;
    /**
     * Field type
     * @see InputType
     * @default InputType.text
     */
    type?: InputType;
    /**
     * Allow to define specific width on rendering
     * @see GridSize
     */
    gridItemSize?: GridSize;
    /**
     * List of options (required options depend of your InputType)
     */
    options?: {
        service?: string;
        /**
         * Subform component declaration
         * @see InputType.subform
         */
        subform?: ({ position, entity, updateEntity, configLine }: SubFormType) => JSX.Element;
        /**
         * Enable subform sortable option. weight : Declare resource property to use for order management.
         * @see InputType.subform
         */
        sortable?: { weight: string };

        label?: string;
        /**
         * resource declaration for subentity (database relation)
         * @see InputType.entity
         */
        resource?: string;

        resourceIdentifier?: string;
        /**
         * Allow multiple choices with InputType.select
         * @see InputType.select
         */
        multiple?: boolean;
        /**
         * Available choices with InputType.select
         * @see InputType.select
         */
        choices?: { value: string | number | boolean; label: string }[];
        /**
         * set render type for InputType.select (select, radio or checkbox)
         * @see InputType.select
         * @see SelectRenderType
         * @default SelectRenderType.select
         */
        render?: SelectRenderType;
        /**
         * Convert text field into WYSIWYG
         * @see InputType.text
         * @default false
         */
        textArea?: boolean;
        /**
         * Enable html readonly
         */
        readOnly?: boolean;
        /**
         * Enable details in label for use in formEntity
         */
        details?: { path: string; prelabel: string; values?: string[] };

        /**
         * Disable clear option on formEntity
         */
        disableClearable?: boolean;
        /**
         * Form entity options stay the same.
         */
        disableAutocomplete?: boolean;
    };
};

export type { ConfigFormType, FormElementType };
export { InputType, SelectRenderType };
