/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigFormType } from './forms/ConfigFormType';
import { ConfigListType } from './lists/ConfigListType';
import { ActionsType } from './ActionsType';

type ResourceType = {
    /**
     * Endpoint path used for this resource
     */
    resourcePath: string;

    identifier?: string;

    /**
     * List of default values for form init.
     * If a default value is not set, the field will not work.
     */
    placeHolder: { [key: string]: any };

    defaultValues?: { [key: string]: any };

    /**
     * yup.object containing validators for each field with constraint.
     */
    validationSchema: any;

    /**
     * Describe edit form structure.
     * @see ConfigFormType for more details
     */
    configForm: ConfigFormType;

    /**
     * Describe listing structure.
     */
    configList: ConfigListType;

    /**
     * Default or custom actions available.
     */
    actions?: ActionsType;

    /**
     * service type
     */
    service?: string;
};

export enum RessourceMode {
    Create,
    Edit,
}

export type { ResourceType };
