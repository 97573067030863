import { Avatar, Chip } from '@mui/material';
import { SERVICE, SERVICE_COLOR, SERVICE_LABEL, SERVICE_LOGO } from '../../constants';

const getAvatar = (label: string, src: string, alt: string) => {
    return (
        <Avatar alt={alt} src={src}>
            {label}
        </Avatar>
    );
};

const getIcon = (service: number): any => {
    switch (service) {
        case SERVICE.CROQKILOS:
            return {
                avatar: getAvatar('CK', SERVICE_LOGO.CROQKILOS, SERVICE_LABEL.CROQKILOS),
            };
            break;
        case SERVICE.CROQBODY:
            return {
                avatar: getAvatar('CB', SERVICE_LOGO.CROQBODY, SERVICE_LABEL.CROQBODY),
            };
            break;
        case SERVICE.YOGICLUB:
            return {
                avatar: getAvatar('YC', SERVICE_LOGO.YOGICLUB, SERVICE_LABEL.YOGICLUB),
            };
            break;
    }
};

const ListServiceCell = (service: number): JSX.Element => {
    const avatar = getIcon(service);
    const label = SERVICE_LABEL[service];
    return (
        <Chip
            {...avatar}
            key={label}
            size="small"
            label={label}
            color="primary"
            sx={{
                backgroundColor: SERVICE_COLOR[service],
                color: 'white',
                marginRight: '5px',
                marginBottom: '5px',
                fontWeight: 'bold',
                fontSize: '0.75rem',
            }}
        />
    );
};

export default ListServiceCell;
