import { /*Avatar, */ Chip } from '@mui/material';
import { ROLE_LABEL_COLOR /*, SERVICE*/ } from 'constants/common';
import { DateConverter } from 'm6BoBuilder';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';

//import { SERVICE_LABEL, SERVICE_LOGO } from '../../constants';

//import NotInterestedIcon from '@mui/icons-material/NotInterested';

const Tag = (element: any, sx?: any): JSX.Element => {
    const finalLabel = element.label;
    return (
        <Chip
            {...element}
            key={finalLabel}
            size="small"
            variant="outlined"
            sx={{
                ...sx,
                marginRight: '5px',
                marginBottom: '5px',
                fontWeight: 'bold',
                fontSize: '0.75rem',
            }}
        />
    );
};
/*
const getAvatar = (label: string, src: string, alt: string) => {
    return (
        <Avatar alt={alt} src={src}>
            {label}
        </Avatar>
    );
};
/*
const getIcon = (service: number): any => {
    switch (service) {
        case SERVICE.CROQKILOS:
            return getAvatar('CK', SERVICE_LOGO.CROQKILOS, SERVICE_LABEL.CROQKILOS);
            break;
        case SERVICE.CROQBODY:
            return getAvatar('CB', SERVICE_LOGO.CROQBODY, SERVICE_LABEL.CROQBODY);
            break;
        case SERVICE.YOGICLUB:
            return getAvatar('YC', SERVICE_LOGO.YOGICLUB, SERVICE_LABEL.YOGICLUB);
            break;
    }
};*/

const ListOfferDatetimeCell = (elements: any, data: any): JSX.Element[] | JSX.Element => {
    return (
        <>
            {elements.length === 0
                ? Tag(
                      {
                          label: '-',
                          avatar: <AccessTimeTwoToneIcon sx={{ fontSize: 14 }} />,
                      },
                      {
                          borderColor: '#00C2A9', //'#F03801',
                      },
                  )
                : Object.keys(elements)
                      .sort()
                      .map((elt: any) => {
                          const label = `${DateConverter.display(elements[elt].createdAt)}`;
                          //const service = elements[elt].service;
                          const backgroundColor = ROLE_LABEL_COLOR[elt];
                          /*if (elements[elt].automaticSubscription === 1) {
                              label = 'Actif';
                          } else {
                              const dateExpiredAt = new Date(elements[elt].createdAt);
                              const now = new Date();
                              if (now < dateExpiredAt) {
                                  label = `Résiliation effective le ${DateConverter.display(elements[elt].createdAt)}`;
                              }
                          }*/
                          return Tag(
                              {
                                  label: label,
                                  avatar: <AccessTimeTwoToneIcon sx={{ fontSize: 14 }} />, //getIcon(service),
                              },
                              {
                                  borderColor: backgroundColor,
                              },
                          );
                      })}
        </>
    );
};

export default ListOfferDatetimeCell;
