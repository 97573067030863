// types
import { ConfigProps } from './berry/types/config';

const config: ConfigProps = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'croqkilos', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'fr', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false,
};

export default config;
