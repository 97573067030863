import { DateConverter } from 'm6BoBuilder';
import { Chip } from '@mui/material';
import WatchLaterTwoToneIcon from '@mui/icons-material/WatchLaterTwoTone';

const OfferListStatusCell = (status: number, data: any): JSX.Element[] | JSX.Element => {
    const { automaticSubscription, expiredAt, state } = data;
    const dateExpiredAt = new Date(expiredAt);
    const now = new Date();
    let label = `Abonnement expiré le ${DateConverter.display(expiredAt)}`;
    let color: 'info' | 'secondary' | 'error' = 'error';
    const sx = {};
    if (state === 'DISABLED') {
        label = `Abonnement résilié`;
        color = 'error';
    } else if (state === 'CANCELED') {
        label = `Abonnement annulé`;
        color = 'error';
    } else {
        if (automaticSubscription === 1) {
            label = `Abonnement reconduit le ${DateConverter.display(expiredAt)}`;
            color = 'info';
        } else {
            if (now < dateExpiredAt) {
                label = `Résiliation effective le ${DateConverter.display(expiredAt)}`;
                color = 'secondary';
            }
        }
    }
    return (
        <Chip
            color={color}
            icon={<WatchLaterTwoToneIcon />}
            size="small"
            label={label}
            sx={{
                ...sx,
                marginRight: '5px',
                marginBottom: '5px',
                fontWeight: 'bold',
                fontSize: '0.75rem',
            }}
        />
    );
};

export default OfferListStatusCell;
