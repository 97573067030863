//import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SubFormType /*, FormElementType, FormInput, FormDate, InputType, FormEntity*/ } from 'm6BoBuilder';
//import { FormikValues } from 'formik';
//import { MeasurementsProperties } from 'config/resources/Measurement';
//import BodyParts, { BodyPartsProperties } from 'config/resources/BodyParts';

const SubformUserMeasurement = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    /*const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? {
            id: '',
            size: 0,
            createdAt: '',
            bodyPart: null,
        },
    );

    useEffect((): void => {
        console.log('entity', entity);
    }, [entity]);*/

    /* configSize: FormElementType = {
        label: 'Taille (en cm)',
        property: MeasurementsProperties.size,
        type: InputType.float,
    };
    const configDate: FormElementType = {
        label: 'Date',
        property: MeasurementsProperties.date,
        type: InputType.date,
    };
    const configBodyParts: FormElementType = {
        label: 'Parties du corps',
        property: MeasurementsProperties.bodyPart,
        type: InputType.entity,
        options: {
            resource: BodyParts.resourcePath,
            label: BodyPartsProperties.title,
        },
    };

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        entity[configLine.property][position] = updatedSubEntity;

        updateEntity({ ...entity }, configLine.property);
    };*/

    return (
        <Grid container spacing={3}>
            {/*<Grid item xs={4} mb={3}>
                <FormInput
                    updateEntity={updateSubEntity}
                    entity={entity && entity[configLine.property][position]}
                    configLine={configSize}
                />
            </Grid>
            <Grid item xs={4}>
                <FormDate
                    updateEntity={updateSubEntity}
                    entity={entity && entity[configLine.property][position]}
                    configLine={configDate}
                />
            </Grid>
            <Grid item xs={4}>
                <FormEntity
                    updateEntity={updateSubEntity}
                    entity={entity && entity[configLine.property][position]}
                    configLine={configBodyParts}
                    keepEntity
                />
            </Grid>*/}
        </Grid>
    );
};

export default SubformUserMeasurement;
