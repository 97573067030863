/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainCard } from 'm6BoBuilder';

import { Button, CardActions, CardContent, Divider, IconButton, Modal, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IconLock, IconLockOff } from '@tabler/icons';
import { User } from 'interfaces/user.interface';

type SubmitData = {
    userId?: number;
};

type LockModalType = { open: boolean; user: User; onClose: () => void; onSubmit: (data: SubmitData) => void };

const LockModal = ({ open, user, onClose, onSubmit }: LockModalType): JSX.Element => {
    const handleSubmit = () => {
        onSubmit({ userId: user.id });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal keepMounted={false} open={open}>
            <MainCard
                sx={{
                    position: 'absolute',
                    width: '80%',
                    maxWidth: 550,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                title={`${user?.locked ? 'Débloquer' : 'Bloquer'} le compte de l'utilisateur "${user.fullname}"`}
                content={false}
                secondary={
                    <IconButton onClick={handleClose} size="large">
                        <Close fontSize="small" />
                    </IconButton>
                }
            >
                <CardContent sx={{ padding: '30px 20px' }}>
                    <Typography>
                        {`Vous êtes sur le point de ${
                            user?.locked ? 'débloquer' : 'bloquer'
                        } le compte de l'utilisateur "${user.email}" (ID:${user.id})`}
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions>
                    <Stack justifyContent="flex-end" direction="row" sx={{ flex: 1 }}>
                        <form onSubmit={handleSubmit}>
                            <Button
                                color="error"
                                size="large"
                                variant="contained"
                                type={'submit'}
                                sx={{ mr: 2 }}
                                startIcon={user.locked ? <IconLockOff /> : <IconLock />}
                            >
                                {`${user?.locked ? 'Débloquer' : 'Bloquer'} le compte`}
                            </Button>
                            <Button color="primary" onClick={handleClose} size="large">
                                {'Annuler'}
                            </Button>
                        </form>
                    </Stack>
                </CardActions>
            </MainCard>
        </Modal>
    );
};

export default LockModal;
