import * as yup from 'yup';
import {
    DateConverter,
    ResourceType,
    InputType,
    SelectRenderType,
    BooleanConverter,
    ImageConverter,
} from 'm6BoBuilder';
import { PUBLISHED_STATUS_OPTIONS } from '../../constants';

const PromotionalNewsletterProperties = {
    id: 'id',
    bodyMedia: 'bodyMedia',
    publicationDate: 'publicationDate',
    endDate: 'endDate',
    status: 'status',
    provenance: 'provenance',
    body: 'body',
    campaign: 'campaign',
};

const PromotionalNewsletter: ResourceType = {
    resourcePath: 'bo-promotional-newsletter',
    placeHolder: {
        [PromotionalNewsletterProperties.id]: 0,
        [PromotionalNewsletterProperties.bodyMedia]: null,
        [PromotionalNewsletterProperties.publicationDate]: undefined,
        [PromotionalNewsletterProperties.endDate]: undefined,
        [PromotionalNewsletterProperties.status]: 0,
        [PromotionalNewsletterProperties.provenance]: '',
        [PromotionalNewsletterProperties.body]: '',
        [PromotionalNewsletterProperties.campaign]: 0,
    },

    validationSchema: yup.object({
        [PromotionalNewsletterProperties.publicationDate]: yup.date().required('Ce champ est obligatoire'),
        [PromotionalNewsletterProperties.endDate]: yup.date().required('Ce champ est obligatoire'),
        [PromotionalNewsletterProperties.status]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Article Newsletter',
        listInputs: [
            {
                label: 'Image',
                property: PromotionalNewsletterProperties.bodyMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Texte',
                property: PromotionalNewsletterProperties.body,
            },
            {
                label: 'Provenance',
                property: PromotionalNewsletterProperties.provenance,
            },
            {
                label: 'Campagne mindbaz',
                property: PromotionalNewsletterProperties.campaign,
                type: InputType.integer,
            },
            {
                label: 'Date de publication',
                property: PromotionalNewsletterProperties.publicationDate,
                type: InputType.date,
            },
            {
                label: 'Date de fin',
                property: PromotionalNewsletterProperties.endDate,
                type: InputType.date,
            },
            {
                label: 'Statut',
                property: PromotionalNewsletterProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
        ],
    },

    configList: {
        label: 'Article Newsletter',
        listProperties: [
            {
                label: 'Image',
                property: PromotionalNewsletterProperties.bodyMedia,
                transform: ImageConverter.display,
            },
            {
                label: 'Publié le',
                property: PromotionalNewsletterProperties.publicationDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Fin le',
                property: PromotionalNewsletterProperties.endDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Texte',
                property: PromotionalNewsletterProperties.body,
            },
            {
                label: 'Provenance',
                property: PromotionalNewsletterProperties.provenance,
            },
            {
                label: 'Campagne mindbaz',
                property: PromotionalNewsletterProperties.campaign,
            },
            {
                label: 'Publié',
                property: PromotionalNewsletterProperties.status,
                transform: (element: string): JSX.Element =>
                    BooleanConverter.display(parseInt(element) > 0 ? true : false),
            },
        ],
        filters: [
            {
                label: 'Provenance',
                property: PromotionalNewsletterProperties.provenance,
            },
            {
                label: 'Campagne mindbaz',
                property: PromotionalNewsletterProperties.campaign,
            },
            {
                label: 'Statut',
                property: PromotionalNewsletterProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default PromotionalNewsletter;
export { PromotionalNewsletterProperties };
