import { useState } from 'react';
import { Grid } from '@mui/material';
import { FormImage, SubFormType, FormElementType, InputType } from 'm6BoBuilder';
import { FormikValues } from 'formik';

const SubformRecipeUserMedia = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? {
            media: null,
            position: null,
        },
    );
    const configMedia: FormElementType = {
        label: 'Image',
        property: `media`,
        type: InputType.image,
        options: {
            resource: 'media_objects',
        },
    };

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        if (subEntity.hasOwnProperty('@id')) {
            entity[configLine.property][position] = updatedSubEntity;
        } else {
            if (entity[configLine.property].length && ![...entity[configLine.property]].pop().hasOwnProperty('@id')) {
                entity[configLine.property].pop();
            }
            entity[configLine.property].push(updatedSubEntity);
        }
        setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);
    };

    return (
        <Grid container spacing={3}>
            <Grid xs={8} item>
                <FormImage updateEntity={updateSubEntity} entity={subEntity} configLine={configMedia} />
            </Grid>
        </Grid>
    );
};

export default SubformRecipeUserMedia;
