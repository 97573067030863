import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';
import { Box, Button, Dialog, Input, Typography, Fab } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Crop, Edit } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { v4 as uuid } from 'uuid';

import ApiService from '../../services/ApiService';
import { ApiContext } from '../../AppBoBuilder';
import { AxiosResponse } from 'axios';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImgContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    paddingTop: '50%',
    backgroundColor: theme.palette.grey[100],
    borderRadius: `${theme.shape.borderRadius}px`,
    '& > img, & > label': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        maxHeight: 'calc(100% - 20px)',
    },
}));

const ActionContainer = styled(Box)(({}) => ({
    position: 'absolute',
    top: 20,
    right: 20,
    '& > *:not(:last-child)': {
        marginRight: 15,
    },
}));

const CropContainer = styled(Box)(({}) => ({
    position: 'relative',
}));

const ModalCtaContainer = styled(Box)(({}) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '35px',
    '& button:not(:last-child)': {
        marginRight: 75,
    },
}));

const FormImage = ({ entity, updateEntity, configLine }: FormElementPropsType): JSX.Element => {
    if (!configLine.options?.resource) {
        throw new Error(`Missing resource type in "${configLine.property}" field options`);
    }

    const apiService: ApiService = useContext(ApiContext);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [cropper, setCropper] = useState<Cropper>();
    const [cropData, setCropData] = useState<string>('');
    const [isCropperOpen, setCropperOpen] = useState<boolean>(false);

    const resource = configLine.options.resource;

    useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    useEffect(() => {
        if (entity[configLine.property])
            setImageUrl(process.env.REACT_APP_API_URL + '/' + entity[configLine.property].webPath);
    }, [entity[configLine.property]]);

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.item(0);
        if (undefined === file || null === file) {
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result == null) {
                return;
            }
            apiService.upload(resource, file).then((data: AxiosResponse) => {
                setSelectedImage(file);
                entity[configLine.property] = data.data;
                updateEntity(entity, configLine.property);
                setImageUrl(process.env.REACT_APP_API_URL + '/' + data.data.webPath);
            });
        };
        reader.readAsDataURL(file);
    };

    const id = `select-img-${configLine.property}_${uuid()}`;

    const getCropData = () => {
        if (typeof cropper !== 'undefined') {
            setCropperOpen(false);
            const cropDataUrl = cropper.getCroppedCanvas().toDataURL();
            setCropData(cropDataUrl);
            // Create file from DataUrl
            const fileType = selectedImage ? selectedImage.type : 'image/png';
            const fileName = selectedImage ? selectedImage.name : `cropped-${Math.round(Math.random() * 10000)}.png`;
            dataUrlToFile(cropDataUrl, fileName, fileType).then((file) => {
                apiService.upload(resource, file).then((data: AxiosResponse) => {
                    entity[configLine.property] = data.data;
                    updateEntity(entity, configLine.property);
                });
            });
        }
    };

    async function dataUrlToFile(dataUrl: string, fileName: string, fileType: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: fileType });
    }

    return (
        <Box>
            <Typography component="h2" variant="h6" mb={1}>
                {configLine.label}
            </Typography>
            <Input type="file" style={{ display: 'none' }} onChange={handleImageUpload} id={id} />
            <ImgContainer>
                {imageUrl ? (
                    <>
                        <img src={cropData ? cropData : imageUrl} />
                        <ActionContainer>
                            <Fab color="primary" size="small" onClick={() => setCropperOpen(true)}>
                                <Crop fontSize="small" />
                            </Fab>
                            <Fab color="primary" size="small">
                                <label
                                    htmlFor={id}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Edit fontSize="small" />
                                </label>
                            </Fab>
                        </ActionContainer>
                    </>
                ) : (
                    <label htmlFor={id}>
                        <Button variant="contained" color="primary" component="span" startIcon={<Add />}>
                            Ajouter une image
                        </Button>
                    </label>
                )}
            </ImgContainer>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={isCropperOpen}
                onClose={() => setCropperOpen(false)}
                PaperProps={{
                    style: {
                        padding: 50,
                    },
                }}
            >
                <CropContainer>
                    <Cropper
                        zoomTo={0}
                        aspectRatio={1.3334}
                        src={imageUrl}
                        viewMode={1}
                        dragMode={'move'}
                        minCropBoxHeight={75}
                        minCropBoxWidth={100}
                        background={false}
                        checkCrossOrigin={false}
                        responsive={true}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                        crop={() => {
                            setTimeout(() => {
                                const canvas_img = window.document.querySelector('img.cropper-hide');
                                if (canvas_img) {
                                    canvas_img.setAttribute('crossorigin', 'anonymous');
                                }
                            }, 100);
                        }}
                    />
                    <ModalCtaContainer>
                        <Button color="primary" onClick={() => setCropperOpen(false)}>
                            Annuler
                        </Button>
                        <Button variant="contained" color="primary" onClick={getCropData}>
                            Recadrer
                        </Button>
                    </ModalCtaContainer>
                </CropContainer>
            </Dialog>
        </Box>
    );
};
export default FormImage;
