/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';

import { IconButton, Tooltip } from '@mui/material';
import { Preview as PreviewIcon } from '@mui/icons-material';

import {
    ImageConverter,
    DateConverter,
    TagConverter,
    InputType,
    ResourceType,
    SelectRenderType,
    BooleanConverter,
    ActionType,
    ActionTypeProps,
} from 'm6BoBuilder';
import SubformRecipeIngredient from '../../components/form/subform/SubformRecipeIngredient';
import DietPlan from './DietPlan';
import SubformRecipeUserMedia from '../../components/form/subform/SubformRecipeUserMedia';
import RecipeCategory, { RecipeCategoryProperties } from './RecipeCategory';
import RecipeLevel from './RecipeLevel';
import Season, { SeasonProperties } from './Season';
import Ingredient, { IngredientProperties } from './Ingredient';
import RecipePublicCategory from './RecipePublicCategory';
import { PUBLISHED_STATUS_OPTIONS, YES_OR_NO_OPTIONS } from '../../constants';

const RecipeProperties = {
    id: 'id',
    title: 'title',
    categories: 'categories',
    seasons: 'seasons',
    kcal: 'kcal',
    isVegan: 'isVegan',
    preparationTime: 'preparationTime',
    cookingTime: 'cookingTime',
    personNumber: 'personNumber',
    portion: 'portion',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    publishedAtStart: 'publishedAtStart',
    recipeIngredients: 'recipeIngredients',
    user: 'user',
    steps: 'steps',
    note: 'note',
    picture: 'picture',
    status: 'status',
    videoUrl: 'videoUrl',
    price: 'price',
    level: 'level',
    userMedias: 'userMedias',
    dietPlans: 'dietPlans',
    isPublic: 'isPublic',
    publicCategory: 'publicCategory',
    totalIngredients: 'totalIngredients',
};

const RecipePreview: ActionType = ({ config, id }: ActionTypeProps): JSX.Element => {
    const handleClick = async () => {
        window.open(`${config.link.replace(':id', id.toString())}`, '_blank');
    };

    return id ? (
        <>
            <Tooltip title={config.label}>
                <IconButton
                    color={'info'}
                    aria-label={config.label}
                    onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleClick();
                    }}
                >
                    {config.icon}
                </IconButton>
            </Tooltip>
        </>
    ) : (
        <></>
    );
};
const Recipe: ResourceType = {
    resourcePath: 'recipes',
    placeHolder: {
        [RecipeProperties.title]: '',
        [RecipeProperties.categories]: [],
        [RecipeProperties.seasons]: [],
        [RecipeProperties.kcal]: 0,
        [RecipeProperties.isVegan]: false,
        [RecipeProperties.preparationTime]: null,
        [RecipeProperties.cookingTime]: null,
        [RecipeProperties.personNumber]: null,
        [RecipeProperties.portion]: null,
        [RecipeProperties.recipeIngredients]: [],
        [RecipeProperties.steps]: '',
        [RecipeProperties.note]: '',
        [RecipeProperties.picture]: null,
        [RecipeProperties.videoUrl]: null,
        [RecipeProperties.price]: 0,
        [RecipeProperties.level]: '',
        [RecipeProperties.userMedias]: [],
        [RecipeProperties.dietPlans]: [],
        [RecipeProperties.user]: null,
        [RecipeProperties.status]: 0,
        [RecipeProperties.publishedAtStart]: null,
        [RecipeProperties.isPublic]: false,
        [RecipeProperties.publicCategory]: '',
        [RecipeProperties.totalIngredients]: 0,
    },
    validationSchema: yup.object({
        [RecipeProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [RecipeProperties.categories]: yup.array().min(1, 'Une recette doit avoir au moins une catégorie associée'),
        [RecipeProperties.seasons]: yup.array().min(1, 'Une recette doit avoir au moins une saison associée'),
        [RecipeProperties.level]: yup.string().required('Une recette doit avoir un niveau associé'),
        [RecipeProperties.isPublic]: yup.boolean().required('Ce champ est obligatoire'),
        [RecipeProperties.preparationTime]: yup.number().required('Ce champ est obligatoire'),
        [RecipeProperties.cookingTime]: yup.number().required('Ce champ est obligatoire'),
        [RecipeProperties.portion]: yup.number().required('Ce champ est obligatoire'),
        [RecipeProperties.personNumber]: yup.number().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Recette',
        listInputs: [
            {
                label: 'Nom',
                property: RecipeProperties.title,
            },
            {
                label: 'Publié',
                property: RecipeProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Publiée le',
                property: RecipeProperties.publishedAtStart,
                type: InputType.date,
            },
            {
                label: 'Catégorie',
                property: RecipeProperties.categories,
                type: InputType.entity,
                options: {
                    resource: RecipeCategory.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Saison',
                property: RecipeProperties.seasons,
                type: InputType.entity,
                options: {
                    resource: Season.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Niveau de difficulté',
                property: RecipeProperties.level,
                type: InputType.entity,
                options: {
                    resource: RecipeLevel.resourcePath,
                },
            },
            {
                label: 'Régime alimentaire',
                property: RecipeProperties.dietPlans,
                type: InputType.entity,
                options: {
                    resource: DietPlan.resourcePath,
                    multiple: true,
                    readOnly: true,
                },
            },
            {
                label: "Kcal (calculé automatiquement lors de l'enregistrement)",
                property: RecipeProperties.kcal,
                type: InputType.integer,
                options: {
                    readOnly: true,
                },
            },
            {
                label: 'Prix (en €)',
                property: RecipeProperties.price,
                type: InputType.float,
                options: {
                    readOnly: true,
                },
            },
            {
                label: 'Cette recette est une recette végétarienne',
                property: RecipeProperties.isVegan,
                type: InputType.select,
                options: {
                    choices: YES_OR_NO_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Temps de préparation',
                property: RecipeProperties.preparationTime,
                type: InputType.integer,
            },
            {
                label: 'Temps de cuisson',
                property: RecipeProperties.cookingTime,
                type: InputType.integer,
            },
            {
                label: 'Portion',
                property: RecipeProperties.portion,
                type: InputType.integer,
            },
            {
                label: 'Nombre de personnes',
                property: RecipeProperties.personNumber,
                type: InputType.integer,
            },
            {
                label: 'Image',
                property: RecipeProperties.picture,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Ingrédients',
                property: RecipeProperties.recipeIngredients,
                type: InputType.subform,
                options: {
                    subform: SubformRecipeIngredient,
                    multiple: true,
                    sortable: {
                        weight: 'position',
                    },
                },
            },
            {
                label: 'Etapes',
                property: RecipeProperties.steps,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Astuces',
                property: RecipeProperties.note,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Video Youtube',
                property: RecipeProperties.videoUrl,
            },
            {
                label: 'Photos ambassadrices (facultatif)',
                property: RecipeProperties.userMedias,
                type: InputType.subform,
                options: {
                    subform: SubformRecipeUserMedia,
                    multiple: true,
                    sortable: {
                        weight: 'position',
                    },
                },
            },
            {
                label: 'Rendre cette recette publique',
                property: RecipeProperties.isPublic,
                type: InputType.boolean,
            },
            {
                label: 'Catégorie de recette du site publique',
                property: RecipeProperties.publicCategory,
                type: InputType.entity,
                options: {
                    resource: RecipePublicCategory.resourcePath,
                },
            },
        ],
        beforeSubmit: (entity: Record<string, unknown>) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { dietPlans, isVegan, ...data } = entity;
            return { ...data, isVegan: isVegan === 'true' ? 1 : 0 };
        },
    },
    configList: {
        label: 'Recettes',
        listProperties: [
            {
                label: '#',
                property: RecipeProperties.id,
                sortable: true,
                styles: {
                    width: '3%',
                },
            },
            {
                label: 'Image',
                property: RecipeProperties.picture,
                transform: ImageConverter.display,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Nom',
                property: RecipeProperties.title,
                sortable: true,
                styles: {
                    minWidth: '25%',
                },
            },
            {
                label: 'Catégories',
                property: RecipeProperties.categories,
                transform: (elements: []): JSX.Element[] => elements.map((elt) => TagConverter.display(elt, 'title')),
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Saisons',
                property: RecipeProperties.seasons,
                transform: (elements: []): JSX.Element[] => elements.map((elt) => TagConverter.display(elt, 'title')),
                styles: {
                    width: '10%',
                },
            },
            /*{
                label: "Nombre d'ingrédients",
                property: RecipeProperties.totalIngredients,
            },*/
            {
                label: 'Auteur',
                property: RecipeProperties.user,
                transform: (elt: Record<string, string>): string => elt.email,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Publié',
                property: RecipeProperties.status,
                sortable: false,
                transform: (elt: number): JSX.Element => BooleanConverter.display(elt > 0 ? true : false),
            },
            {
                label: 'Date de publication',
                property: RecipeProperties.publishedAtStart,
                transform: DateConverter.display,
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: '#ID',
                property: RecipeProperties.id,
                type: InputType.text,
            },
            {
                label: 'Nom',
                property: RecipeProperties.title,
            },
            {
                label: 'Catégorie',
                property: `${RecipeProperties.categories}.id`,
                type: InputType.entity,
                options: {
                    label: RecipeCategoryProperties.title,
                    resource: RecipeCategory.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Saison',
                property: `${RecipeProperties.seasons}.id`,
                type: InputType.entity,
                options: {
                    label: SeasonProperties.title,
                    resource: Season.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Ingredient',
                property: `${RecipeProperties.recipeIngredients}.ingredient.id`,
                type: InputType.entity,
                options: {
                    label: IngredientProperties.title,
                    resource: Ingredient.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Statut',
                property: RecipeProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
        ],
    },
    actions: {
        delete: true,
        customs: [
            {
                config: {
                    label: 'Visualiser la recette',
                    link: `${process.env.REACT_APP_WWW_URL}/mes-recettes/recette?id=:id`,
                    icon: <PreviewIcon />,
                },
                component: RecipePreview,
            },
        ],
    },
};

export default Recipe;
export { RecipeProperties };
