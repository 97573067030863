import { Route } from 'react-router-dom';
import { UserType } from 'm6BoBuilder';
import MenuPlanningScreen from '../screens/MenuPlanningScreen';
import ParameterScreen from '../screens/ParameterScreen';
import Parameter from './resources/Parameter';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateCustomRouteList = (user: UserType): JSX.Element[] => {
    const customRouteList: JSX.Element[] = [];
    customRouteList.push(<Route key={'menu_planning'} path={`/menus-planning`} element={<MenuPlanningScreen />} />);
    customRouteList.push(
        <Route key={Parameter.resourcePath} path={`/${Parameter.resourcePath}`} element={<ParameterScreen />} />,
    );

    return customRouteList;
};

export default generateCustomRouteList;
