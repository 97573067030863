import {
    CardContent,
    ListItemText,
    List,
    ListItem,
    ListItemButton,
    ListItemSecondaryAction,
    IconButton,
    ListItemAvatar,
    Avatar,
    Button,
    Icon,
} from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import { Preview as PreviewIcon } from '@mui/icons-material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IconTrash } from '@tabler/icons';
import ImagePlaceholder from '../../m6BoBuilder/images/no-image.jpg';

interface MenuType {
    id: number;
    publishedAt: string;
    menuMeals: MenuMealType[];
    state: boolean;
}

interface MenuMealType {
    id: number;
    meal: MealType;
}

interface MealType {
    id: number;
    type: 0 | 1 | 2 | 3;
    mealRecipes: MealRecipeType[];
}

interface MealRecipeType {
    id: number;
    position: number;
    recipe: RecipeType;
}

interface RecipeType {
    id: number;
    categories: Record<string, undefined>[];
    recipeIngredients: RecipeIngredientType[];
    seasons: Record<string, undefined>[];
    level: Record<string, undefined>;
    dietPlans: DietPlanType[];
    title: string;
    picture: Record<string, undefined>;
    cookingTime: string;
    preparationTime: string;
    portion: string;
    nbPerspon: string;
    kcal: string;
    price: number;
    isVegan: boolean;
    note: string;
    steps: string;
}

interface RecipeIngredientType {
    ingredient: IngredientType;
    quantity: number;
}

interface IngredientType {
    title: string;
    unit: number;
}

interface DietPlanType {
    title: string;
}

interface MenuCardProps {
    index: number;
    label: string;
    menu: MenuType;
    menuKcal: number[];
    position: number;
    menuMeal: MenuMealType;
    onUpdate: (item: any) => void;
    onRecipeClick: (mealRecipe: MealRecipeType) => void;
    handleMealRecipeDetails: (recipe: RecipeType) => void;
    handleMealRecipeDelete: (mealRecipeId: number, otherRecipes: MealRecipeType[]) => void;
    addEmptyRecipe: (menu: MenuType, position: number, day: number, recipePosition: number) => void;
}

const MenuCard = ({
    index,
    label,
    menu,
    menuKcal,
    position,
    menuMeal,
    onUpdate,
    onRecipeClick,
    handleMealRecipeDetails,
    handleMealRecipeDelete,
    addEmptyRecipe,
}: MenuCardProps): JSX.Element => {
    const onDragEnd = (droppedItem: any) => {
        onUpdate(droppedItem);
    };
    return (
        <CardContent>
            <h3>
                {label} ({menuKcal[position]} kcal)
            </h3>

            <List>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`droppable_${index}_${position}`}>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {menuMeal &&
                                    menuMeal.meal.mealRecipes.map((mealRecipe: MealRecipeType, rPos: number) => {
                                        // Retrieve recipe image or take placeholder
                                        let recipeImgSrc = '';
                                        if (mealRecipe.recipe?.picture?.medium) {
                                            recipeImgSrc = mealRecipe.recipe?.picture?.medium;
                                        } else {
                                            recipeImgSrc = ImagePlaceholder;
                                        }
                                        recipeImgSrc = `${process.env.REACT_APP_API_URL}${recipeImgSrc}`;
                                        return (
                                            <Draggable
                                                key={`draggable_${rPos}`}
                                                draggableId={`draggable_${rPos}`}
                                                index={rPos}
                                            >
                                                {(provided, snapshop) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                        style={{
                                                            marginBottom: '13px',
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        <ListItem
                                                            key={`recipe_${mealRecipe.id}`}
                                                            disablePadding
                                                            onClick={() => onRecipeClick(mealRecipe)}
                                                        >
                                                            <ListItemButton>
                                                                <DragIndicator>
                                                                    <Icon />
                                                                </DragIndicator>
                                                                <ListItemAvatar>
                                                                    <Avatar variant="rounded" src={recipeImgSrc} />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={
                                                                        mealRecipe.recipe?.title ?? 'pas de recette'
                                                                    }
                                                                    secondary={
                                                                        mealRecipe.recipe?.kcal
                                                                            ? `${mealRecipe.recipe.kcal} kcal`
                                                                            : ''
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                            {menuMeal.meal.mealRecipes.length > 0 && (
                                                                <ListItemSecondaryAction>
                                                                    {mealRecipe.recipe && (
                                                                        <IconButton
                                                                            color={'info'}
                                                                            aria-label="visualiser"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                ev.stopPropagation();
                                                                                handleMealRecipeDetails(
                                                                                    mealRecipe.recipe,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <PreviewIcon />
                                                                        </IconButton>
                                                                    )}
                                                                    {menuMeal.meal.mealRecipes.length > 1 && (
                                                                        <IconButton
                                                                            color={'error'}
                                                                            aria-label="supprimer"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                ev.stopPropagation();
                                                                                handleMealRecipeDelete(
                                                                                    mealRecipe.id,
                                                                                    menuMeal.meal.mealRecipes.filter(
                                                                                        (recipe) =>
                                                                                            recipe.id !== mealRecipe.id,
                                                                                    ),
                                                                                );
                                                                            }}
                                                                        >
                                                                            <IconTrash />
                                                                        </IconButton>
                                                                    )}
                                                                </ListItemSecondaryAction>
                                                            )}
                                                        </ListItem>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {menuMeal && (
                    <Button onClick={() => addEmptyRecipe(menu, position, index, menuMeal.meal.mealRecipes.length)}>
                        + Ajouter une recette
                    </Button>
                )}
            </List>
        </CardContent>
    );
};
export default MenuCard;
