import ListDateCell from 'components/table/ListDateCell';
import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const UserWeightProperties = {
    createdAt: 'createdAt',
    weight: 'weight',
};

const UserWeight: ResourceType = {
    resourcePath: 'weights',
    placeHolder: {
        [UserWeightProperties.createdAt]: null,
        [UserWeightProperties.weight]: null,
    },
    validationSchema: yup.object({}),
    configForm: {
        label: 'Abonnement',
        listInputs: [
            {
                label: 'Poids',
                property: UserWeightProperties.weight,
                type: InputType.float,
            },
            {
                label: 'Date',
                property: UserWeightProperties.createdAt,
                type: InputType.date,
            },
        ],
    },
    configList: {
        label: 'Abonnements',
        listProperties: [
            {
                label: 'Poids',
                property: UserWeightProperties.weight,
                styles: {
                    width: '60%',
                },
            },
            {
                label: 'Date',
                property: UserWeightProperties.createdAt,
                transform: ListDateCell,
            },
        ],
        allowAdd: false,
    },
    /*actions: {
        delete: true,
    },*/
};

export default UserWeight;
export { UserWeightProperties };
