import { ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const SeasonProperties = {
    id: 'id',
    title: 'title',
    totalRecipes: 'totalRecipes',
};

const Season: ResourceType = {
    resourcePath: 'seasons',
    placeHolder: {
        [SeasonProperties.title]: '',
        [SeasonProperties.totalRecipes]: 0,
    },

    validationSchema: yup.object({
        [SeasonProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Saisons',
        listInputs: [
            {
                label: 'Nom',
                property: 'title',
            },
        ],
    },

    configList: {
        label: 'Saisons',
        listProperties: [
            {
                label: '#',
                property: SeasonProperties.id,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Saison',
                property: SeasonProperties.title,
            },
            {
                label: 'Nombre de recettes',
                property: SeasonProperties.totalRecipes,
            },
        ],
        allowAdd: false,
    },
};

export default Season;
export { SeasonProperties };
