//import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { SubFormType, FormElementType, FormInput, FormDate, InputType } from 'm6BoBuilder';
import { FormikValues } from 'formik';
import { UserWeightProperties } from 'config/resources/UserWeight';

const SubformUserWeight = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    /*const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? {
            weight: 0,
            id: 0,
            createdAt: '',
        },
    );*/
    const configWeight: FormElementType = {
        label: 'Poids',
        property: UserWeightProperties.weight,
        type: InputType.float,
    };
    const configDate: FormElementType = {
        label: 'Date',
        property: UserWeightProperties.createdAt,
        type: InputType.date,
    };

    /*useEffect(() => {
        setSubEntity(entity[configLine.property][position] ?? subEntity);
    }, [entity]);*/

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        entity[configLine.property][position] = updatedSubEntity;

        //setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={7} mb={3}>
                <FormInput
                    updateEntity={updateSubEntity}
                    entity={entity && entity[configLine.property][position]}
                    configLine={configWeight}
                />
            </Grid>
            <Grid item xs={5}>
                <FormDate
                    updateEntity={updateSubEntity}
                    entity={entity && entity[configLine.property][position]}
                    configLine={configDate}
                />
            </Grid>
        </Grid>
    );
};

export default SubformUserWeight;
