import * as yup from 'yup';
import {
    DateConverter,
    ResourceType,
    InputType,
    ImageConverter,
    SelectRenderType,
    BooleanConverter,
} from 'm6BoBuilder';
import { PUBLISHED_STATUS_OPTIONS } from '../../constants';
import { PromotionalCommunicationProperties } from './PromotionalCommunication';

const PublicPromoPopinProperties = {
    id: 'id',
    title: 'title',
    campaign: 'campaign',
    origin: 'origin',
    button: 'button',
    desktopMedia: 'desktopMedia',
    mobileMedia: 'mobileMedia',
    publicationDate: 'publicationDate',
    endDate: 'endDate',
    status: 'status',
};

const PublicPromoPopin: ResourceType = {
    resourcePath: 'bo-public-promo-popins',
    placeHolder: {
        [PublicPromoPopinProperties.id]: 0,
        [PublicPromoPopinProperties.title]: '',
        [PublicPromoPopinProperties.campaign]: 0,
        [PublicPromoPopinProperties.origin]: '',
        [PublicPromoPopinProperties.button]: '',
        [PublicPromoPopinProperties.desktopMedia]: null,
        [PublicPromoPopinProperties.mobileMedia]: null,
        [PublicPromoPopinProperties.publicationDate]: undefined,
        [PublicPromoPopinProperties.endDate]: undefined,
        [PublicPromoPopinProperties.status]: 0,
    },
    validationSchema: yup.object({
        [PublicPromoPopinProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [PublicPromoPopinProperties.publicationDate]: yup.date().required('Ce champ est obligatoire'),
        [PublicPromoPopinProperties.endDate]: yup.date().required('Ce champ est obligatoire'),
        [PublicPromoPopinProperties.status]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Popin du site public',
        listInputs: [
            {
                label: 'Titre',
                property: PublicPromoPopinProperties.title,
            },
            {
                label: 'Bouton : Label',
                property: PublicPromoPopinProperties.button,
            },
            {
                label: 'Campagne mindbaz',
                property: PublicPromoPopinProperties.campaign,
                type: InputType.integer,
            },
            {
                label: 'Provenance',
                property: PublicPromoPopinProperties.origin,
            },
            {
                label: 'Image PC',
                property: PublicPromoPopinProperties.desktopMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Image Mobile',
                property: PublicPromoPopinProperties.mobileMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Date de début',
                property: PublicPromoPopinProperties.publicationDate,
                type: InputType.date,
            },
            {
                label: 'Date de fin',
                property: PublicPromoPopinProperties.endDate,
                type: InputType.date,
            },
            {
                label: 'Status',
                property: PromotionalCommunicationProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
        ],
    },

    configList: {
        label: 'Block promotionnel privée',
        listProperties: [
            {
                label: 'ID',
                property: PublicPromoPopinProperties.id,
                sortable: true,
            },
            {
                label: 'Image',
                property: PublicPromoPopinProperties.desktopMedia,
                transform: ImageConverter.display,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Titre',
                property: PublicPromoPopinProperties.title,
            },
            {
                label: 'Campagne',
                property: PublicPromoPopinProperties.origin,
            },
            {
                label: 'Date de début',
                property: PublicPromoPopinProperties.publicationDate,
                transform: DateConverter.display,
            },
            {
                label: 'Date de fin',
                property: PublicPromoPopinProperties.endDate,
                transform: DateConverter.display,
            },
            {
                label: 'Publié',
                property: PromotionalCommunicationProperties.status,
                transform: (element: string): JSX.Element =>
                    BooleanConverter.display(parseInt(element) > 0 ? true : false),
            },
        ],
        filters: [
            {
                label: 'Titre',
                property: PublicPromoPopinProperties.title,
            },
            {
                label: 'Campagne',
                property: PublicPromoPopinProperties.origin,
            },
            {
                label: 'Statut',
                property: PromotionalCommunicationProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default PublicPromoPopin;
export { PublicPromoPopinProperties };
