import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UserState } from './user.interface';

export const useUserStore = create<UserState>()(
    persist(
        (set) => ({
            isAuth: false,
            isActiveUser: false,
            user: undefined,
            token: undefined,
            refreshToken: undefined,
            rememberMe: undefined,
            setIsAuth: (status) => {
                set({
                    isAuth: status,
                });
            },
            setIsActiveUser: (status) => {
                set({
                    isActiveUser: status,
                });
            },
            setRememberMe: (data) =>
                set({
                    rememberMe: data,
                }),
            setUser: (user) =>
                set({
                    user,
                }),
            setToken: (token) =>
                set({
                    token,
                }),
            setRefreshToken: (refreshToken) =>
                set({
                    refreshToken,
                }),
            clearUser: () => {
                set({
                    user: undefined,
                    isAuth: false,
                });
            },
            logout: () =>
                set({
                    isAuth: false,
                    user: undefined,
                    rememberMe: undefined,
                }),
            clear: () => set({}, true),
        }),
        {
            name: 'user',
        },
    ),
);
