import { ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const PrivateArticleCategoryProperties = {
    title: 'title',
    totalArticles: 'totalArticles',
};

const PrivateArticleCategory: ResourceType = {
    resourcePath: 'bo-private_article_categories',
    placeHolder: {
        [PrivateArticleCategoryProperties.title]: '',
        [PrivateArticleCategoryProperties.totalArticles]: 0,
    },
    validationSchema: yup.object({
        [PrivateArticleCategoryProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: "Catégories d'article privé",
        listInputs: [
            {
                label: 'Nom',
                property: 'title',
            },
        ],
    },
    configList: {
        label: "Catégories d'article privé",
        listProperties: [
            {
                label: 'Catégorie',
                property: PrivateArticleCategoryProperties.title,
                sortable: true,
            },
            {
                label: "Nombre d'articles",
                property: PrivateArticleCategoryProperties.totalArticles,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default PrivateArticleCategory;
export { PrivateArticleCategoryProperties };
