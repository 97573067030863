/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';
import DietType, { DietTypeProperties } from './DietType';
import moment from 'moment';
import { RessourceMode } from 'm6BoBuilder/types/ResourceType';
import SubformUserWeight from 'components/form/subform/SubformUserWeight';
import SubformUserMeasurement from 'components/form/subform/SubformUserMeasurement';
import UserCKForm from 'components/form/UserCKForm';

const UserCKProperties = {
    id: 'id',
    weights: 'weights',
    dietType: 'dietType',
    measurements: 'measurements',
};

const UserCK: ResourceType = {
    resourcePath: 'users',
    placeHolder: {
        [UserCKProperties.id]: '',
        [UserCKProperties.weights]: [],
        [UserCKProperties.dietType]: null,
        [UserCKProperties.measurements]: [],
    },
    validationSchema: yup.object({
        [UserCKProperties.weights]: yup
            .array(
                yup.object({
                    weight: yup.number(),
                    createdAt: yup.string(),
                }),
            )
            .optional(),
    }),
    configForm: {
        label: 'Infos CK',
        editForm: UserCKForm,
        listInputs: [
            {
                label: 'Type de programme',
                property: UserCKProperties.dietType,
                type: InputType.entity,
                options: {
                    resource: DietType.resourcePath,
                    multiple: false,
                    label: DietTypeProperties.name,
                },
            },
            {
                label: "Pesées de l'utilisateur",
                property: UserCKProperties.weights,
                type: InputType.subform,
                options: {
                    subform: SubformUserWeight,
                },
            },
            {
                label: "Mensurations de l'utilisateur",
                property: UserCKProperties.measurements,
                type: InputType.subform,
                options: {
                    subform: SubformUserMeasurement,
                },
            },
        ],
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeSubmit: (entity: Record<string, any>, _context: RessourceMode): Record<string, any> => {
            for (const elem of entity?.weights) {
                elem.createdAt = elem.createdAt
                    ? moment(elem.createdAt.toString()).format('YYYY-MM-DDT00:00:00.000') + 'Z'
                    : undefined;
            }

            /*if (Array.isArray(entity.weights)) {
                //const dateArray = entity.weights.map((e) => new Date(e.createdAt));
                const mostRecentWeight = entity.weights.reduce((a, b) =>
                    new Date(a.createdAt) > new Date(b.createdAt) ? a : b,
                );
                entity.weight = mostRecentWeight.weight;
            }*/
            delete entity.weight;
            return entity;
        },
    },
    configList: {
        label: '',
        listProperties: [],
        allowAdd: false,
    },
};

export default UserCK;
export { UserCKProperties };
