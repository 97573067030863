/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState, MouseEvent, useMemo } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Fab,
    Typography,
    Box,
    Checkbox,
    Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { SelectableTableRow } from './table/SelectableTableRow';

// Types
import { ConfigListType, MassActionType, TableListElementType } from '../types/lists/ConfigListType';
import { ListFilterType } from '../types/lists/ListFilterType';
import { ResourceType } from '../types/ResourceType';
import { ActionType } from '../types/ActionsType';

// Project import
import { ApiContext } from '../AppBoBuilder';
import { flashMessage, flashType } from './Flash';
import TableListFilters from './TableListFilters';
import CustomLoader from './CustomLoader';
import TablePaginator from './table/TablePaginator';
import ApiService from '../services/ApiService';
import WhiteBox from '../components/styled/WhiteBox';
import DeleteModal from './DeleteModal';
import { FilterType } from 'm6BoBuilder/types/FilterType';
import { useFilterSearchParams } from '../hooks/useFilterParams';

import { useDeleteResourceById, useGetAllResources } from 'm6BoBuilder/services/useQueries';

type TableListProps = {
    resource: ResourceType;
    config: ConfigListType;
    actions?: ActionType[];
    listPath?: string | null;
    showAddCta?: boolean;
    navigateOnClick?: boolean;
    forceRefresh?: boolean;
};

type Order = {
    property: string;
    order: 'asc' | 'desc';
};

const initialFilter: ListFilterType = { page: 1, itemsPerPage: 20 };

const AddCta: any = styled(Fab)(() => ({
    position: 'fixed',
    bottom: 30,
    right: 30,
    zIndex: 500,
}));

const MassActionsContainer: any = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
    minHeight: '37px',
    '& > p:first-of-type': {
        marginRight: '30px',
    },
}));

const TableList = ({
    resource,
    config,
    actions,
    listPath = null,
    showAddCta,
    navigateOnClick,
}: TableListProps): JSX.Element => {
    const apiService: ApiService = useContext(ApiContext);

    const defaultOrder: Order = {
        property: 'order[id]',
        order: 'desc',
    };

    if (config.defaultOrder !== undefined) {
        defaultOrder.property = `order[${config.defaultOrder.property}]`;
        defaultOrder.order = config.defaultOrder.order;
    }
    initialFilter.itemsPerPage = config.itemsPerPage ?? initialFilter.itemsPerPage;

    // Add default filter value set in config
    config.filters?.forEach((filter: FilterType) => {
        if (filter.options?.default !== undefined) {
            initialFilter[filter.property] = filter.options.default;
        }
    });

    const [getFilterSearchParams] = useFilterSearchParams();
    const searchParams = getFilterSearchParams();

    const orderKeys = Object.keys(searchParams)
        .map((key) => {
            if (key.startsWith('order[')) {
                return key;
            }
        })
        .filter(function (el) {
            return el != null;
        });
    if (orderKeys && orderKeys.length > 0) {
        const property = orderKeys.pop() ?? defaultOrder.property;
        defaultOrder.property = property;
        defaultOrder.order = searchParams[property];
    } else {
        searchParams[defaultOrder.property] = defaultOrder.order;
    }

    const [filters, setFilters] = useState({ ...initialFilter, ...searchParams });

    const [order, setOrder] = useState<Order>(defaultOrder);

    const [selected, setSelected] = useState<string[]>([]);

    const [isDeleteModalVisible, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const navigate = selected.length > 0 ? false : navigateOnClick;
    const theme = useTheme();

    const resourceListPath = listPath ?? config.resourcePath ?? resource.resourcePath;

    // QUERIES
    const { isFetching, data: { list, totalItems } = { list: [], totalItems: 0 } } = useGetAllResources(
        resourceListPath,
        filters,
    );

    const { mutateAsync: deleteResourceMutateAsync, isSuccess: isSuccessDeleteResource } = useDeleteResourceById();

    useEffect(() => {
        if (isSuccessDeleteResource) {
            flashMessage(`${resource.configForm.label} #${deleteId} a bien été supprimée.`, flashType.SUCCESS);
            setDeleteModal(false);
        }
    }, [isSuccessDeleteResource]);

    const onSelect = (checked: boolean, element: { id: string }) => {
        if (checked) {
            setSelected([...selected, element[resource.identifier ?? 'id']]);
        } else {
            setSelected(selected.filter((id) => id !== element[resource.identifier ?? 'id']));
        }
    };

    const handleChangePage = (selectedPage: number) => {
        setFilters({ ...filters, page: selectedPage });
    };

    const handleChangeRowsPerPage = (rowPerPage: number) => {
        setFilters({ ...filters, itemsPerPage: rowPerPage });
    };

    const handleSort = (property: any) => (_event: any) => {
        delete filters[order.property];
        const isAsc = order.property === property && order.order === 'asc';

        setOrder({ property, order: isAsc ? 'desc' : 'asc' });
        setFilters({ ...filters, [property]: isAsc ? 'desc' : 'asc' });
    };

    const showDeleteModal = (id: string) => (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event?.stopPropagation();
        setDeleteId(id);
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        await deleteResourceMutateAsync({ resourcePath: resource.resourcePath, resourceId: deleteId });
    };

    const applyAction = async (action: (entity: any, apiService: ApiService) => Promise<void>) => {
        const promiseList: Promise<any>[] = [];
        selected.forEach(async (id) => {
            promiseList.push(
                action(
                    list.find((element: any) => element[resource.identifier ?? 'id'] === id),
                    apiService,
                ),
            );
        });
        await Promise.all(promiseList);
        flashMessage(`${selected.length} ${resource.configForm.label} ont bien été modifiées`);
        setSelected([]);
    };

    const selectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelected(list.map((element: any) => element[resource.identifier ?? 'id']));
        } else {
            setSelected([]);
        }
    };

    const table = useMemo(
        () =>
            list?.map((element: any, index: number) => (
                <SelectableTableRow
                    checked={selected.includes(element[resource.identifier ?? 'id'])}
                    navigate={navigate}
                    onSelect={onSelect}
                    element={element}
                    index={index}
                    resource={resource}
                    theme={theme}
                    config={config}
                    showDeleteModal={showDeleteModal}
                    actions={actions}
                    key={element[resource.identifier ?? 'id']}
                />
            )),
        [list, selected],
    );

    return (
        <>
            {config.allowAdd && showAddCta !== false && (
                <AddCta color="secondary" component={Link} to={`/${resource.resourcePath}/new`}>
                    <AddIcon fontSize="medium" />
                </AddCta>
            )}
            <WhiteBox>
                <Grid container spacing={2}>
                    {config.filters && (
                        <Grid item xs={12}>
                            <TableListFilters
                                filters={config.filters}
                                defaultValues={initialFilter}
                                values={filters}
                                updateFilters={(f) => {
                                    setFilters(f);
                                }}
                            />
                        </Grid>
                    )}
                    {/*totalItems > 0 && (
                        <Grid container justifyContent="right" alignItems="right" sx={{ marginRight: 3 }}>
                            <Typography variant="h5" component={'p'} sx={{ p: 1 }}>
                                {totalItems} Élément(s)
                            </Typography>
                        </Grid>
                    )*/}
                    <Grid item xs={12}>
                        {isFetching || !list ? (
                            <Grid container justifyContent="center" alignItems="center">
                                <CustomLoader />
                            </Grid>
                        ) : list.length ? (
                            <>
                                {config.selectable && (
                                    <MassActionsContainer>
                                        {selected.length > 0 && (
                                            <>
                                                <Typography variant="h4" component={'p'}>
                                                    {`${selected.length} Élément${
                                                        selected.length > 1 ? 's' : ''
                                                    } sélectionné${selected.length > 1 ? 's' : ''}`}
                                                </Typography>
                                                {config.massActions?.map((action: MassActionType, index) => {
                                                    return (
                                                        <Button
                                                            sx={{ marginRight: 3 }}
                                                            key={index}
                                                            variant="contained"
                                                            onClick={() => applyAction(action.action)}
                                                        >
                                                            {action.label}
                                                        </Button>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </MassActionsContainer>
                                )}

                                <TableContainer>
                                    <Table stickyHeader className={resource.resourcePath}>
                                        <TableHead>
                                            <TableRow>
                                                {config?.selectable && (
                                                    <TableCell key={'sort-header'} style={{ fontWeight: 'bold' }}>
                                                        <Checkbox
                                                            checked={selected.length > 0}
                                                            indeterminate={
                                                                selected.length > 0 && selected.length !== list.length
                                                            }
                                                            onChange={selectAll}
                                                        />
                                                    </TableCell>
                                                )}
                                                {config.listProperties.map((configLine: TableListElementType) => (
                                                    <TableCell key={configLine.property} style={{ fontWeight: 'bold' }}>
                                                        {configLine.sortable ? (
                                                            <TableSortLabel
                                                                active={
                                                                    order.property === `order[${configLine.property}]`
                                                                }
                                                                direction={order.order}
                                                                onClick={handleSort(`order[${configLine.property}]`)}
                                                            >
                                                                {configLine.label}
                                                            </TableSortLabel>
                                                        ) : (
                                                            configLine.label
                                                        )}
                                                    </TableCell>
                                                ))}
                                                {resource.actions && (
                                                    <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{table}</TableBody>
                                    </Table>
                                    <TablePaginator
                                        rowsPerPageOptions={[5, 10, 20, 50]}
                                        count={totalItems}
                                        rowsPerPage={filters.itemsPerPage ?? 20}
                                        page={filters && filters.page ? filters.page - 1 : 0}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelRowsPerPage={`${config.label} par page`}
                                    />
                                </TableContainer>
                            </>
                        ) : (
                            <Typography sx={{ p: 2 }}>Aucun élément</Typography>
                        )}
                    </Grid>
                </Grid>
            </WhiteBox>

            <DeleteModal
                modalOpen={isDeleteModalVisible}
                closeModal={() => setDeleteModal(false)}
                handleDelete={handleDelete}
            />
        </>
    );
};
export default TableList;
