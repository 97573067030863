import { create } from 'zustand';
import { AppState } from './app.interface';

export const useAppStore = create<AppState>((set) => ({
    _hasHydrated: false,
    isOnline: false,
    exerciceOverlayId: undefined,
    showProgramReview: false,
    isLoadingRefresh: false,
    refreshBodyPartExerciceVideosData: { id: undefined, page: undefined },
    refreshTop5: false,
    refreshCurrentLive: false,
    refreshLives: false,
    setHasHydrated: (state) => {
        set({
            _hasHydrated: state,
        });
    },
    setIsloadingRefresh: (state) => {
        set({
            isLoadingRefresh: state,
        });
    },
    setIsOnline: (state) => {
        set({
            isOnline: state,
        });
    },
    setExerciceOverlayId: (exerciceOverlayId) => {
        set({
            exerciceOverlayId,
        });
    },
    setShowProgramReview: (showProgramReview) => {
        set({
            showProgramReview,
        });
    },
    setRefreshBodyPartExerciceVideosData: (id?: number, page?: number) =>
        set({
            refreshBodyPartExerciceVideosData: {
                id,
                page,
            },
        }),
    setRefreshTop5: (status) =>
        set({
            refreshTop5: status,
        }),
    setRefreshCurrentLive: (status) =>
        set({
            refreshCurrentLive: status,
        }),
    setRefreshLives: (status) =>
        set({
            refreshLives: status,
        }),
}));
