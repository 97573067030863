import * as yup from 'yup';

import { Preview as PreviewIcon } from '@mui/icons-material';

import {
    InputType,
    ResourceType,
    ImageConverter,
    TagConverter,
    BooleanConverter,
    DateConverter,
    SelectRenderType,
    ActionType,
    ActionTypeProps,
} from 'm6BoBuilder';
import ArticleCategory, { ArticleCategoryProperties } from './ArticleCategory';
import SubformAssetObject from 'components/form/subform/SubformAssetObject';
import ArticleAuthor, { ArticleAuthorProperties } from './ArticleAuthor';
import { PUBLISHED_STATUS_OPTIONS as STATUS_OPTIONS } from '../../constants';
import ChoiceConverter from 'm6BoBuilder/converters/ChoiceConverter';
import { IconButton, Tooltip } from '@mui/material';

const ArticleProperties = {
    title: 'title',
    publicationDate: 'publicationDate',
    status: 'status',
    category: 'category',
    body: 'body',
    teaser: 'teaser',
    video: 'video',
    titleMedia: 'titleMedia',
    bodyMedia: 'bodyMedia',
    pdf: 'pdf',
    type: 'type',
    isHighlighted: 'isHighlighted',
    author: 'author',
    modifiedDate: 'modifiedDate',
};

const ArticlePreviewAction: ActionType = ({ config, id }: ActionTypeProps): JSX.Element => {
    const handleClick = async () => {
        window.open(`${config.link.replace(':id', id.toString())}`, '_blank');
    };

    return id ? (
        <>
            <Tooltip title={config.label}>
                <IconButton
                    color={'info'}
                    aria-label={config.label}
                    onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleClick();
                    }}
                >
                    {config.icon}
                </IconButton>
            </Tooltip>
        </>
    ) : (
        <></>
    );
};

const Article: ResourceType = {
    resourcePath: 'bo-articles',
    placeHolder: {
        [ArticleProperties.title]: '',
        [ArticleProperties.category]: [],
        [ArticleProperties.body]: '',
        [ArticleProperties.teaser]: '',
        [ArticleProperties.status]: 0,
        [ArticleProperties.video]: '',
        [ArticleProperties.titleMedia]: null,
        [ArticleProperties.bodyMedia]: null,
        [ArticleProperties.pdf]: null,
        [ArticleProperties.publicationDate]: new Date(),
        [ArticleProperties.type]: 0,
        [ArticleProperties.isHighlighted]: false,
        [ArticleProperties.author]: null,
        [ArticleProperties.modifiedDate]: null,
    },
    validationSchema: yup.object({
        [ArticleProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [ArticleProperties.pdf]: yup.object().nullable(),
    }),
    configForm: {
        label: 'Article',
        listInputs: [
            {
                label: 'Titre',
                property: ArticleProperties.title,
            },
            {
                label: 'Catégories',
                property: ArticleProperties.category,
                type: InputType.entity,
                options: {
                    resource: ArticleCategory.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Auteur(e)',
                property: ArticleProperties.author,
                type: InputType.entity,
                options: {
                    resource: ArticleAuthor.resourcePath,
                    label: ArticleAuthorProperties.displayName,
                    multiple: false,
                },
            },
            {
                label: 'Date de publication',
                property: ArticleProperties.publicationDate,
                type: InputType.datetime,
            },
            {
                label: 'Statut',
                property: ArticleProperties.status,
                type: InputType.select,
                options: {
                    choices: STATUS_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Teaser',
                property: ArticleProperties.teaser,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Contenu',
                property: ArticleProperties.body,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Type',
                property: ArticleProperties.type,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 0, label: 'Article' },
                        { value: 1, label: 'Vidéo' },
                        { value: 2, label: 'PDF' },
                    ],
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Image en-tête',
                property: ArticleProperties.titleMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Image contenu',
                property: ArticleProperties.bodyMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Url Youtube',
                property: ArticleProperties.video,
            },
            {
                label: 'Mettre en avant',
                property: ArticleProperties.isHighlighted,
                type: InputType.boolean,
            },
            {
                label: 'PDF',
                property: ArticleProperties.pdf,
                type: InputType.subform,
                options: {
                    subform: SubformAssetObject,
                    multiple: false,
                },
            },
            {
                label: 'Date de modification',
                property: ArticleProperties.modifiedDate,
                options: {
                    readOnly: true,
                },
            },
        ],
    },
    configList: {
        label: 'Articles',
        listProperties: [
            {
                label: 'Nom',
                property: ArticleProperties.title,
                sortable: true,
            },
            {
                label: 'Auteur(e)',
                property: `${ArticleProperties.author}.${ArticleAuthorProperties.displayName}`,
                sortable: true,
            },
            {
                label: 'Image',
                property: ArticleProperties.titleMedia,
                transform: ImageConverter.display,
            },
            {
                label: 'Catégories',
                property: ArticleProperties.category,
                transform: (elements: []): JSX.Element[] => elements.map((elt) => TagConverter.display(elt, 'title')),
            },
            {
                label: 'Type',
                property: ArticleProperties.type,
                transform: (element: number) =>
                    ChoiceConverter.display(element, [
                        { value: 0, label: 'Article' },
                        { value: 1, label: 'Vidéo' },
                        { value: 2, label: 'PDF' },
                    ]),
            },
            {
                label: 'Publié',
                property: ArticleProperties.status,
                transform: (element: string): JSX.Element => BooleanConverter.display(parseInt(element) > 0),
                sortable: true,
            },
            {
                label: 'Date de publication',
                property: ArticleProperties.publicationDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Date de modification',
                property: ArticleProperties.modifiedDate,
                transform: DateConverter.display,
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: ArticleProperties.title,
            },
            {
                label: 'Auteur(e)',
                property: `${ArticleProperties.author}.slug`,
                type: InputType.entity,
                options: {
                    label: ArticleAuthorProperties.displayName,
                    resource: ArticleAuthor.resourcePath,
                    resourceIdentifier: 'slug',
                },
            },
            {
                label: 'Catégorie',
                property: `${ArticleProperties.category}.id`,
                type: InputType.entity,
                options: {
                    label: ArticleCategoryProperties.title,
                    resource: ArticleCategory.resourcePath,
                },
            },
            {
                label: 'Type',
                property: ArticleProperties.type,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 0, label: 'Article' },
                        { value: 1, label: 'Vidéo' },
                        { value: 2, label: 'PDF' },
                    ],
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Statut',
                property: ArticleProperties.status,
                type: InputType.select,
                options: {
                    choices: STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
        ],
        defaultOrder: {
            property: ArticleProperties.publicationDate,
            order: 'desc',
        },
    },
    actions: {
        delete: true,
        customs: [
            {
                config: {
                    label: "Visualiser l'article",
                    link: `${process.env.REACT_PUBLIC_WWW_URL}/actus/:id`,
                    icon: <PreviewIcon />,
                },
                component: ArticlePreviewAction,
            },
        ],
    },
};

export default Article;
export { ArticleProperties };
