interface EnvironmentConfiguration {
    baseUrl: string;
    baseUrlCB: string;
    baseUrlYC: string;
}

export const Config: EnvironmentConfiguration = {
    baseUrl: process.env.REACT_APP_API_URL ?? '',
    baseUrlCB: process.env.REACT_APP_CB_API_URL ?? '',
    baseUrlYC: process.env.REACT_APP_YG_API_URL ?? '',
};
