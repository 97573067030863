import { useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { SubFormType, FormElementType, InputType, FormImage } from 'm6BoBuilder';
import { FormikValues } from 'formik';
import { AssetObjectProperties } from 'config/resources/AssetObject';
import FormFile from 'm6BoBuilder/components/form/FormFile';

const SubformAssetObject = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const [subEntity, setSubEntity] = useState(
        entity[configLine.property] ?? {
            id: 0,
            thumbnail: null,
            asset: null,
        },
    );

    const configImage: FormElementType = {
        label: 'Miniature',
        property: AssetObjectProperties.thumbnail,
        type: InputType.image,
        options: {
            resource: 'media_objects',
        },
    };

    const configAsset: FormElementType = {
        label: 'Fichier',
        property: AssetObjectProperties.asset,
        type: InputType.file,
        options: {
            resource: 'media_objects',
        },
    };

    useEffect(() => {
        setSubEntity(entity[configLine.property][position] ?? subEntity);
    }, [entity]);

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        entity[configLine.property] = updatedSubEntity;
        setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);
    };

    return (
        <Grid style={{ backgroundColor: '#EEEEEE', marginLeft: '16px', padding: '16px' }} container spacing={3}>
            <Grid xs={8} item>
                <FormFile updateEntity={updateSubEntity} entity={subEntity} configLine={configAsset} />
            </Grid>
            <Divider />
            <Grid xs={8} item>
                <FormImage updateEntity={updateSubEntity} entity={subEntity} configLine={configImage} />
            </Grid>
        </Grid>
    );
};

export default SubformAssetObject;
