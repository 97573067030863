import axios, { AxiosInstance } from 'axios';

let httpCB: null | AxiosInstance = null;

if (process.env.REACT_APP_CB_API_URL) {
    httpCB = axios.create({
        baseURL: process.env.REACT_APP_CB_API_URL,
        headers: {
            'Content-type': 'application/ld+json',
            Accept: 'application/ld+json',
        },
    });
}

export default httpCB;
