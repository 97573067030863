/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getObjectProperty = (
    obj: any,
    accessor: string,
    transform?: (result: string, obj: any) => string,
    defaultValue?: any,
): string => {
    defaultValue = defaultValue === undefined ? '' : defaultValue;
    const props = accessor.split('.');
    let result = obj;
    for (let i = 0; i < props.length; i++) result = result[props[i]] ?? defaultValue;
    return typeof transform == 'function' ? transform(result, obj) : result;
};
