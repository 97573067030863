import * as yup from 'yup';
import { DateConverter, ResourceType, InputType, SelectRenderType, BooleanConverter } from 'm6BoBuilder';
import { PUBLISHED_STATUS_OPTIONS } from '../../constants';

const PromotionalCommunicationProperties = {
    id: 'id',
    title: 'title',
    publicationDate: 'publicationDate',
    endDate: 'endDate',
    status: 'status',
    target: 'target',
    url: 'url',
    text: 'text',
};

const PromotionalCommunication: ResourceType = {
    resourcePath: 'bo-promotional-communications',
    placeHolder: {
        [PromotionalCommunicationProperties.title]: '',
        [PromotionalCommunicationProperties.publicationDate]: undefined,
        [PromotionalCommunicationProperties.endDate]: undefined,
        [PromotionalCommunicationProperties.status]: 0,
        [PromotionalCommunicationProperties.target]: false,
        [PromotionalCommunicationProperties.url]: '',
        [PromotionalCommunicationProperties.text]: '',
    },
    validationSchema: yup.object({
        [PromotionalCommunicationProperties.title]: yup.string().required('Ce champ est obligatoire'),
        [PromotionalCommunicationProperties.publicationDate]: yup.date().required('Ce champ est obligatoire'),
        [PromotionalCommunicationProperties.endDate]: yup.date().required('Ce champ est obligatoire'),
        [PromotionalCommunicationProperties.status]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Bandeau promotionnel',
        listInputs: [
            {
                label: 'Titre',
                property: PromotionalCommunicationProperties.title,
            },
            {
                label: 'Date de publication',
                property: PromotionalCommunicationProperties.publicationDate,
                type: InputType.date,
            },
            {
                label: 'Date de fin',
                property: PromotionalCommunicationProperties.endDate,
                type: InputType.date,
            },
            {
                label: 'Status',
                property: PromotionalCommunicationProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Bouton : Contenu',
                property: PromotionalCommunicationProperties.text,
            },
            {
                label: 'Bouton : Ouvrable dans un nouvel onglet',
                property: PromotionalCommunicationProperties.target,
                type: InputType.boolean,
            },
            {
                label: 'Bouton : Url',
                property: PromotionalCommunicationProperties.url,
            },
        ],
    },

    configList: {
        label: 'Bandeau promotionnel',
        listProperties: [
            {
                label: 'ID',
                property: PromotionalCommunicationProperties.id,
                sortable: true,
            },
            {
                label: 'Ttire',
                property: PromotionalCommunicationProperties.title,
                sortable: true,
            },
            {
                label: 'Date de publication',
                property: PromotionalCommunicationProperties.publicationDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Date de fin',
                property: PromotionalCommunicationProperties.endDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Publié',
                property: PromotionalCommunicationProperties.status,
                transform: (element: string): JSX.Element =>
                    BooleanConverter.display(parseInt(element) > 0 ? true : false),
            },
        ],
        filters: [
            {
                label: 'Titre',
                property: PromotionalCommunicationProperties.title,
            },
            {
                label: 'Statut',
                property: PromotionalCommunicationProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default PromotionalCommunication;
export { PromotionalCommunicationProperties };
