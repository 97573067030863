import { ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const RecipePublicCategoryProperties = {
    id: 'id',
    title: 'title',
    description: 'description',
    totalRecipes: 'totalRecipes',
};

const RecipePublicCategory: ResourceType = {
    resourcePath: 'recipe_public_categories',
    placeHolder: {
        [RecipePublicCategoryProperties.title]: '',
        [RecipePublicCategoryProperties.description]: '',
        [RecipePublicCategoryProperties.totalRecipes]: 0,
    },

    validationSchema: yup.object({
        [RecipePublicCategoryProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Catégories de recettes sur le site public',
        listInputs: [
            {
                label: 'Titre',
                property: 'title',
            },
            {
                label: 'Description',
                property: 'description',
            },
        ],
    },

    configList: {
        label: 'Catégories',
        listProperties: [
            {
                label: '#',
                property: RecipePublicCategoryProperties.id,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Titre',
                property: RecipePublicCategoryProperties.title,
                sortable: true,
            },
            {
                label: 'Nombre de recettes',
                property: RecipePublicCategoryProperties.totalRecipes,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default RecipePublicCategory;
export { RecipePublicCategoryProperties };
