import { useState } from 'react';
import { Grid } from '@mui/material';
import { FormEntity, FormInput, SubFormType, FormElementType, InputType } from 'm6BoBuilder';
import { FormikValues } from 'formik';
import Recipe from 'config/resources/Recipe';
import { MealRecipeProperties } from 'config/resources/MealRecipe';

const SubformMealRecipe = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? {
            position: null,
            recipe: null,
        },
    );
    const configRecipe: FormElementType = {
        label: 'Recette',
        property: MealRecipeProperties.recipe,
        options: {
            resource: Recipe.resourcePath,
        },
    };
    const configPosition: FormElementType = {
        label: 'Position',
        property: MealRecipeProperties.position,
        type: InputType.integer,
    };

    // useEffect(() => {
    //     //Prevent unused data on entities
    //     if (subEntity.recipe !== null) {
    //         subEntity.recipe = subEntity.recipe['@id'];
    //     }
    //     //subEntity.meal = `/${Meal.resourcePath}/${entity.id}`;
    //     delete subEntity.menu;
    //     setSubEntity(subEntity);
    // }, [entity]);

    const updateSubEntity = (updatedSubEntity: FormikValues) => {
        if (subEntity.hasOwnProperty('@id')) {
            entity[configLine.property][position] = updatedSubEntity;
        } else {
            if (entity[configLine.property].length && ![...entity[configLine.property]].pop().hasOwnProperty('@id')) {
                entity[configLine.property].pop();
            }
            entity[configLine.property].push(updatedSubEntity);
        }
        setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <FormEntity entity={subEntity} updateEntity={updateSubEntity} configLine={configRecipe} />
            </Grid>
            <Grid item xs={3}>
                <FormInput entity={subEntity} updateEntity={updateSubEntity} configLine={configPosition} />
            </Grid>
        </Grid>
    );
};

export default SubformMealRecipe;
