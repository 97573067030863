import MediaType from '../types/MediaType';
import { Avatar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import imagePlaceholder from '../images/no-image.jpg';
const ImgContainer = styled(Box)(() => ({
    minWidth: 100,
    paddingTop: '62.5%',
    position: 'relative',
    '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        display: 'block',
        borderRadius: '10px',
    },
}));
class ImageConverter {
    static display = (media: MediaType): JSX.Element => {
        const url = media.medium ? process.env.REACT_APP_API_URL + media.medium : imagePlaceholder;
        return (
            <ImgContainer>
                <img src={url} />
            </ImgContainer>
        );
    };

    static avatar = (media: MediaType): JSX.Element => {
        const url = media.medium ? process.env.REACT_APP_API_URL + media.medium : imagePlaceholder;
        return <Avatar src={url} color="inherit" sx={{ width: 64, height: 64 }}></Avatar>;
    };
}

export default ImageConverter;
