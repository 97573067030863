import { ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const RecipeLevelProperties = {
    id: 'id',
    title: 'title',
    totalRecipes: 'totalRecipes',
};

const RecipeLevel: ResourceType = {
    resourcePath: 'recipe_levels',
    placeHolder: {
        [RecipeLevelProperties.title]: '',
        [RecipeLevelProperties.totalRecipes]: 0,
    },

    validationSchema: yup.object({
        [RecipeLevelProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Levels',
        listInputs: [
            {
                label: 'Nom',
                property: 'title',
            },
        ],
    },

    configList: {
        label: 'Levels',
        listProperties: [
            {
                label: '#',
                property: RecipeLevelProperties.id,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Saison',
                property: RecipeLevelProperties.title,
                sortable: true,
            },
            {
                label: 'Nombre de recettes',
                property: RecipeLevelProperties.totalRecipes,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: RecipeLevelProperties.title,
            },
        ],
    },
};

export default RecipeLevel;
export { RecipeLevelProperties };
