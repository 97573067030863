import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import inputTypes from 'm6BoBuilder/types/forms/InputTypes';
import { FormElementType, InputType } from 'm6BoBuilder/types/forms/ConfigFormType';
import { EditFormType } from 'm6BoBuilder/types/forms/EditFormType';
import UserMeasurementsCard from 'components/UserMeasurementsCard';
import UserWeightsCard from 'components/UserWeightsCard';

const ErrorLabel = styled(Typography)(({ theme }) => ({
    marginTop: 5,
    color: theme.palette.error.main,
}));

const UserCKForm = ({ entity, config, errors, handleEntityUpdate, inputSize }: EditFormType): JSX.Element => {
    return (
        <>
            <Grid container spacing={3} justifyContent={'start'}>
                {config.listInputs.map((configLine: FormElementType, index: number) => {
                    switch (configLine.property) {
                        case 'weights':
                            return <UserWeightsCard userId={entity.id} />;
                            break;
                        case 'measurements':
                            return <UserMeasurementsCard userId={entity.id} />;
                            break;
                    }
                    if (['weights', 'measurements'].includes(configLine.property)) {
                        return;
                    }
                    const FormElement = inputTypes[configLine.type ?? InputType.text];
                    const onError = errors[configLine.property];
                    return (
                        <Grid
                            item
                            xs={inputSize}
                            md={inputSize}
                            lg={configLine.gridItemSize ? configLine.gridItemSize : inputSize}
                            key={index}
                            sx={{ position: 'relative' }}
                        >
                            <FormElement
                                error={onError}
                                entity={entity}
                                updateEntity={handleEntityUpdate}
                                configLine={configLine}
                            />
                            {onError && <ErrorLabel variant={'subtitle2'}>{errors[configLine.property]}</ErrorLabel>}
                        </Grid>
                    );
                })}

                {/*<UserWeightsCard userId={entity.id} />
                <UserMeasurementsCard userId={entity.id} />*/}
            </Grid>
        </>
    );
};

export default UserCKForm;
