import * as yup from 'yup';
import { ResourceType, DateConverter, InputType, SelectRenderType } from 'm6BoBuilder';
import DietType, { DietTypeProperties } from './DietType';
import { UserProperties } from './User';

const MenuProperties = {
    id: 'id',
    title: 'title',
    publishedAt: 'publishedAt',
    type: 'type',
    status: 'status',
    user: 'user',
};

const statusChoices = [
    { value: 2, label: 'Publié' },
    { value: 1, label: 'Planifié' },
    { value: 0, label: 'Brouillon' },
];

const Menu: ResourceType = {
    resourcePath: 'bo-menus',
    placeHolder: {
        [MenuProperties.id]: 0,
        [MenuProperties.title]: '',
        [MenuProperties.status]: 0,
        [MenuProperties.publishedAt]: undefined,
        [MenuProperties.type]: undefined,
    },
    validationSchema: yup.object({
        [MenuProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Type de menu',
        listInputs: [
            {
                label: 'Titre',
                property: MenuProperties.title,
            },
            {
                label: 'Status',
                property: MenuProperties.status,
                type: InputType.select,
                options: {
                    choices: statusChoices,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Date de publication',
                property: MenuProperties.publishedAt,
                type: InputType.date,
            },
            {
                label: 'Type',
                property: MenuProperties.type,
                type: InputType.entity,
                options: {
                    label: DietTypeProperties.name,
                    resource: DietType.resourcePath,
                },
            },
        ],
    },

    configList: {
        label: 'Type de menu',
        listProperties: [
            {
                label: 'ID',
                property: MenuProperties.id,
                sortable: true,
            },
            {
                label: 'Title',
                property: MenuProperties.title,
                sortable: true,
            },
            {
                label: 'Publié le',
                property: MenuProperties.publishedAt,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Status',
                property: MenuProperties.status,
                sortable: true,
            },
            {
                label: 'Type',
                property: `${MenuProperties.type}.${DietTypeProperties.name}`,
                sortable: true,
            },
            {
                label: 'Auteur',
                property: `${MenuProperties.user}.${UserProperties.fullname}`,
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: MenuProperties.title,
                type: InputType.text,
            },
            {
                label: 'Type',
                property: `${MenuProperties.type}.${DietTypeProperties.id}`,
                type: InputType.entity,
                options: {
                    label: DietTypeProperties.name,
                    resource: DietType.resourcePath,
                },
            },
            {
                label: 'Status',
                property: MenuProperties.status,
                type: InputType.select,
                options: {
                    choices: statusChoices,
                    render: SelectRenderType.select,
                },
            },
        ],
    },
};

export default Menu;
export { MenuProperties };
