import * as yup from 'yup';
import { BooleanConverter, ImageConverter, InputType, ResourceType, SelectRenderType } from 'm6BoBuilder';

const ReviewStatusOptions = [
    { value: 0, label: 'Brouillon' },
    { value: 2, label: 'Publié' },
];

const ReviewProperties = {
    slug: 'slug',
    firstname: 'firstname',
    age: 'age',
    status: 'status',
    body: 'body',
    headerMedia: 'headerMedia',
    beforeMedia: 'beforeMedia',
    afterMedia: 'afterMedia',
    headerImage: 'headerImage',
    beforeImage: 'beforeImage',
    afterImage: 'afterImage',
    lostWeight: 'lostWeight',
    headerText: 'headerText',
    secondBody: 'secondBody',
    createdAt: 'createdAt',
    service: 'service',
    author: 'author',
    months: 'months',
};

const Review: ResourceType = {
    resourcePath: 'bo-reviews',
    identifier: 'slug',
    placeHolder: {
        [ReviewProperties.firstname]: '',
        [ReviewProperties.age]: 0,
        [ReviewProperties.status]: 0,
        [ReviewProperties.body]: '',
        [ReviewProperties.headerMedia]: null,
        [ReviewProperties.beforeMedia]: null,
        [ReviewProperties.afterMedia]: null,
        [ReviewProperties.headerImage]: null,
        [ReviewProperties.beforeImage]: null,
        [ReviewProperties.afterImage]: null,
        [ReviewProperties.lostWeight]: 0,
        [ReviewProperties.headerText]: '',
        [ReviewProperties.secondBody]: '',
        [ReviewProperties.createdAt]: undefined,
        [ReviewProperties.service]: 0,
        [ReviewProperties.months]: 0,
    },

    validationSchema: yup.object({
        [ReviewProperties.firstname]: yup.string().required('Ce champ est obligatoire'),
        [ReviewProperties.age]: yup.number().required('Ce champ est obligatoire'),
        [ReviewProperties.body]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Témoignage',
        listInputs: [
            {
                label: 'Statut',
                property: ReviewProperties.status,
                type: InputType.select,
                options: {
                    choices: ReviewStatusOptions,
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Prénom',
                property: ReviewProperties.firstname,
            },
            {
                label: 'Âge',
                property: ReviewProperties.age,
                type: InputType.integer,
            },
            {
                label: 'Poids perdu',
                property: ReviewProperties.lostWeight,
                type: InputType.float,
            },
            {
                label: 'Texte (Body)',
                property: ReviewProperties.body,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Texte de la couverture (Header Text)',
                property: ReviewProperties.headerText,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Texte 2 (Second Body)',
                property: ReviewProperties.secondBody,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Image de couverture',
                property: ReviewProperties.headerMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Image avant',
                property: ReviewProperties.beforeMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Image après',
                property: ReviewProperties.afterMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Mois',
                property: ReviewProperties.months,
                type: InputType.integer,
            },
        ],
        beforeSubmit: (entity: Record<string, unknown>) => {
            entity.status = Number(entity.status).toString();
            return entity;
        },
    },
    configList: {
        label: 'Témoignages',
        listProperties: [
            {
                label: 'Couverture',
                property: ReviewProperties.headerMedia,
                transform: ImageConverter.display,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Prénom',
                property: ReviewProperties.firstname,
                sortable: true,
            },
            {
                label: 'Âge',
                property: ReviewProperties.age,
            },
            {
                label: 'Publié',
                property: ReviewProperties.status,
                sortable: true,
                transform: (elt: number): JSX.Element => BooleanConverter.display(elt > 0 ? true : false),
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Prénom',
                property: ReviewProperties.firstname,
            },
            {
                label: 'Statut',
                property: ReviewProperties.status,
                type: InputType.select,
                options: {
                    choices: ReviewStatusOptions,
                    render: SelectRenderType.select,
                },
            },
        ],
    },
    actions: {
        delete: true,
    },
};

export default Review;
export { ReviewProperties };
