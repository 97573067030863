/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainCard } from 'm6BoBuilder';
import {
    Button,
    CardActions,
    CardContent,
    Divider,
    IconButton,
    Modal,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Close, PersonRemove } from '@mui/icons-material';

import { User } from 'interfaces/user.interface';
import { useState } from 'react';

type SubmitData = {
    userId?: number;
    userToMergeEmail?: string;
};

type MergeModalType = { open: boolean; user: User; onClose: () => void; onSubmit: (data: SubmitData) => void };

const MergeModal = ({ open, user, onClose, onSubmit }: MergeModalType): JSX.Element => {
    const [userToMerge, setUserToMerge] = useState<string>('');

    const handleUserToMergeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setUserToMerge(event?.target.value);
    };

    const handleSubmit = () => {
        onSubmit({ userId: user.id, userToMergeEmail: userToMerge });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal keepMounted={false} open={open}>
            <MainCard
                sx={{
                    position: 'absolute',
                    width: '80%',
                    maxWidth: 550,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                title={`Fusionner le compte ${user.fullname ?? `${user.fullname} `}${user.email} (ID:${user.id})`}
                content={false}
                secondary={
                    <IconButton onClick={handleClose} size="large">
                        <Close fontSize="small" />
                    </IconButton>
                }
            >
                <CardContent sx={{ flexDirection: 'column' }}>
                    <Typography variant="body1">
                        {`Voulez-vous fusionner le compte 
                        ${user.fullname ?? `${user.fullname} `}${user.email} (ID:${user.id}) avec un autre compte ?`}
                    </Typography>
                    <TextField
                        id="userToMerge"
                        label="Email du compte à fusionner"
                        variant="outlined"
                        type="text"
                        fullWidth
                        required
                        sx={{ mt: 2, mr: 2 }}
                        onChange={handleUserToMergeChange}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: '1.125rem',
                            fontWeight: 500,
                            mr: 1,
                            mt: 1.75,
                            mb: 0.75,
                        }}
                    >
                        Cette action est irréversible.
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions>
                    <Stack justifyContent="flex-end" direction="row" sx={{ flex: 1 }}>
                        <form onSubmit={handleSubmit}>
                            <Button
                                color="error"
                                size="large"
                                variant="contained"
                                type={'submit'}
                                sx={{ mr: 2 }}
                                startIcon={<PersonRemove />}
                            >
                                {`Fusionner les comptes`}
                            </Button>
                            <Button color="primary" onClick={handleClose} size="large">
                                {'Annuler'}
                            </Button>
                        </form>
                    </Stack>
                </CardActions>
            </MainCard>
        </Modal>
    );
};

export default MergeModal;
