/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import Button from '@mui/material/Button';
import { Grid, Icon, Typography, IconButton, Stack } from '@mui/material';
import { DragIndicator, Add } from '@mui/icons-material';
import { IconTrash } from '@tabler/icons';

import { FormElementPropsType } from '../../types/forms/FormElementPropsType';
import EntityType from '../../types/EntityType';
import { styled } from '@mui/material/styles';

type SubformContainer = {
    subId: string;
    subElement: any;
};

type SubFormItemProps = {
    isDragging: boolean;
};

type SubformElementProperties = {
    elem: SubformContainer | null;
    position: number;
};

const SubFormItem = styled(Grid)<SubFormItemProps>(({ theme, isDragging }) => ({
    padding: '10px 0',
    position: 'relative',
    backgroundColor: isDragging ? theme.palette.primary.light : theme.palette.background.default,
    borderRadius: `${theme.shape.borderRadius}px`,
    '&::after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        zIndex: -10,
        boxShadow: theme.shadows[10],
        opacity: isDragging ? 1 : 0,
    },
}));

const createSubList = (
    elements: Array<any> | any | null,
    multiple: boolean,
): Array<SubformContainer> | SubformContainer | null => {
    if (elements === undefined || elements === null) {
        if (multiple) return [];
        else return null;
    }
    if (multiple) {
        return elements.map((element: EntityType): SubformContainer => {
            return {
                subId: uuid(),
                subElement: element,
            };
        });
    } else {
        return {
            subId: uuid(),
            subElement: elements,
        };
    }
};

const FormSubform = ({ entity, updateEntity, configLine }: FormElementPropsType): JSX.Element => {
    if (undefined === configLine.options?.subform) {
        throw new Error(`Missing subform declaration in "${configLine.property}" field options`);
    }

    const multiple = configLine.options.multiple !== false;
    const weight = undefined !== configLine.options?.sortable ? configLine.options.sortable.weight : null;
    const Subform = configLine.options.subform;

    if (weight && multiple) {
        entity[configLine.property] = entity[configLine.property]
            .sort((a: any, b: any) => a[weight] - b[weight])
            .map((element: any, index: number): EntityType => {
                element[weight] = index;
                return { ...element };
            });
    }
    const [subFormList, setSubFormList] = useState<SubformContainer[] | SubformContainer | null>(
        createSubList(entity[configLine.property], multiple),
    );

    const regenerateSubList = (
        subList: Array<EntityType>,
        subFormList: Array<SubformContainer>,
    ): Array<SubformContainer> => {
        return subFormList.map((element: SubformContainer, index: number): SubformContainer => {
            const subElement = subList[index];
            if (weight) {
                subElement[weight] = index;
            }
            return { ...element, subElement: { ...subElement } };
        });
    };

    const revertSubList = (elements: Array<any>): Array<EntityType> => {
        return elements.map(({ subElement }: any, index: number): EntityType => {
            if (weight) {
                subElement[weight] = index;
            }
            return { ...subElement };
        });
    };

    const handleAddSubForm = () => {
        let updatedEntity = entity;
        const newElement: any = {};
        if (weight !== null && multiple) {
            newElement[weight] = entity[configLine.property].length;
        }
        if (multiple && Array.isArray(subFormList)) {
            //entity[configLine.property].push(newElement);
            const elements = entity[configLine.property];
            updatedEntity = {
                ...updatedEntity,
                [configLine.property]: [...elements, newElement],
            };
            setSubFormList([
                ...subFormList,
                {
                    subId: uuid(),
                    subElement: newElement,
                },
            ]);
        } else {
            updatedEntity = {
                ...updatedEntity,
                [configLine.property]: newElement,
            };
            setSubFormList(createSubList(entity[configLine.property], multiple));
        }
        updateEntity({ ...updatedEntity }, configLine.property);
    };

    const handleRemoveSubForm = async (position: number) => {
        const updatedSubFormList = Array.isArray(subFormList)
            ? regenerateSubList(entity[configLine.property], subFormList)
            : null;

        const newSubFormList = Array.isArray(updatedSubFormList)
            ? updatedSubFormList.filter((_element, index) => index !== position)
            : null;

        //step required, trigger useStates
        const updatedSubList = Array.isArray(newSubFormList) ? revertSubList(newSubFormList) : null;
        const updatedEntity = {
            ...entity,
            [configLine.property]: updatedSubList,
        };

        updateEntity({ ...updatedEntity }, configLine.property);
        setSubFormList(multiple === true ? newSubFormList : null);
    };

    const handleDrop = (droppedItem: any) => {
        if (!Array.isArray(subFormList)) {
            return;
        }
        if (weight === null) {
            throw new Error(`Missing weight property declaration in "${configLine.property}" options`);
        }
        if (!droppedItem.destination) return;

        const updatedList = entity[configLine.property];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        const result = updatedList.map((element: any, index: number) => {
            element[weight] = index;
            return element;
        });
        result.sort((a: any, b: any) => a[weight] - b[weight]);

        //step required, trigger useStates
        const updatedEntity = { ...entity, [configLine.property]: result };
        updateEntity(updatedEntity, configLine.property);

        const updatedList2 = subFormList;
        const [reorderedItem2] = updatedList2.splice(droppedItem.source.index, 1);
        updatedList2.splice(droppedItem.destination.index, 0, reorderedItem2);
        const result2 = updatedList2.map((element: SubformContainer, index: number) => {
            element.subElement[weight] = index;
            return element;
        });

        result2.sort((a: SubformContainer, b: SubformContainer) => a.subElement[weight] - b.subElement[weight]);
        setSubFormList(result2);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const SubformElement = ({ elem, position }: SubformElementProperties) => {
        return (
            // eslint-disable-next-line react/prop-types
            <Grid container key={`grid_${configLine.property}_${position}`}>
                <Grid item xs={11}>
                    <Subform position={position} entity={entity} updateEntity={updateEntity} configLine={configLine} />
                </Grid>
                <Grid item xs={1}>
                    <IconButton color="error" onClick={() => handleRemoveSubForm(position)}>
                        <IconTrash />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    // Not sortable list
    if (undefined === configLine.options?.sortable || multiple === false) {
        return (
            <>
                <Typography component="h2" variant="h6" mb={5}>
                    {configLine.label}
                </Typography>
                {Array.isArray(subFormList)
                    ? subFormList.map((_element: SubformContainer, position: number) => (
                          <SubformElement key={position} elem={_element} position={position} />
                      ))
                    : subFormList && <SubformElement elem={subFormList} position={1} />}
                {(configLine.options.multiple !== false || subFormList === null) && (
                    <Button variant="outlined" size="small" onClick={handleAddSubForm}>
                        + Ajouter
                    </Button>
                )}
            </>
        );
    }

    // Sortable list
    return (
        <>
            <Typography component="h2" variant="h6" mb={5}>
                {configLine.label}
            </Typography>
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {Array.isArray(subFormList) &&
                                subFormList.map((element: SubformContainer, position: number) => (
                                    <Draggable
                                        key={`draggable_${element.subId}`}
                                        draggableId={`draggable_${element.subId}`}
                                        index={position}
                                    >
                                        {(provided, snapshop) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                style={{
                                                    marginBottom: '13px',
                                                    ...provided.draggableProps.style,
                                                }}
                                            >
                                                <SubFormItem
                                                    isDragging={snapshop.isDragging}
                                                    container
                                                    alignItems={'center'}
                                                >
                                                    <Grid item xs={2} sx={{ cursor: 'grab', paddingLeft: '10px' }}>
                                                        <DragIndicator>
                                                            <Icon />
                                                        </DragIndicator>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Subform
                                                            position={position}
                                                            entity={entity}
                                                            updateEntity={updateEntity}
                                                            configLine={configLine}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => handleRemoveSubForm(position)}
                                                        >
                                                            <IconTrash />
                                                        </IconButton>
                                                    </Grid>
                                                </SubFormItem>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {(configLine.options.multiple !== false || subFormList === null) && (
                <Stack direction="row" justifyContent="flex-end" sx={{ marginTop: '25px' }}>
                    <Button variant="contained" color="secondary" startIcon={<Add />} onClick={handleAddSubForm}>
                        Ajouter un élément
                    </Button>
                </Stack>
            )}
        </>
    );
};

export default FormSubform;
