import { StatusOptionType } from './type';

export enum OFFER_DURATION {
    ONE_MONTH = 1,
    THREE_MONTH = 3,
    SIX_MONTH = 6,
    ONE_YEAR = 12,
}

export const OFFER_DURATION_OPTIONS: StatusOptionType[] = [
    { value: OFFER_DURATION.ONE_MONTH, label: '1 mois' },
    { value: OFFER_DURATION.THREE_MONTH, label: '3 mois' },
    { value: OFFER_DURATION.SIX_MONTH, label: '6 mois' },
    { value: OFFER_DURATION.ONE_YEAR, label: '12 mois' },
];

export enum OFFER_STATE {
    SUBSCRIPTION = 'SUBSCRIPTION',
    RECONDUCTION = 'RECONDUCTION',
    CANCELED = 'CANCELED',
    DISABLATION_SCHEDULDED = 'DISABLATION_SCHEDULDED',
    DISABLED = 'DISABLED',
    PENDING = 'PENDING',
}

export const OFFER_STATE_OPTIONS: StatusOptionType[] = [
    { value: OFFER_STATE.SUBSCRIPTION, label: 'Inscription' },
    { value: OFFER_STATE.RECONDUCTION, label: 'Reconduction' },
    { value: OFFER_STATE.CANCELED, label: 'Annulé' },
    { value: OFFER_STATE.DISABLATION_SCHEDULDED, label: 'En cours de résiliation' },
    { value: OFFER_STATE.DISABLED, label: 'Résilié' },
    { value: OFFER_STATE.PENDING, label: 'En attente de paiement' },
];
