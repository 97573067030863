import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export type FilterSearchParamsResult = {
    [key: string]: any;
};

export type SetFilterSearchParamsParams = {
    [key: string]: any;
};

export const useFilterSearchParams = (
    defaultFilter?: SetFilterSearchParamsParams,
): [() => FilterSearchParamsResult, ({ page, sort, filters }: SetFilterSearchParamsParams) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const getFilterSearchParams = useCallback((): FilterSearchParamsResult => {
        const queryFilter = [...searchParams].reduce((acc, param) => {
            const [key, value] = param;
            if (acc[key]) {
                acc[key] = Array.isArray(acc[key]) ? [...acc[key], value] : [acc[key], value];
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});

        return { page: 1, ...defaultFilter, ...queryFilter };
    }, [searchParams]);

    const setFilterSearchParams = (filters: SetFilterSearchParamsParams): void => {
        // clean filters
        Object.keys(filters).forEach((key) => filters[key] === undefined && delete filters[key]);
        setSearchParams(filters);
    };

    return [getFilterSearchParams, setFilterSearchParams];
};
