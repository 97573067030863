import { StatusOptionType } from './type';

export const INGREDIENT_UNITS: string[] = ['Rien', 'g', 'kg', 'l', 'ml', 'cl', 'dl', 'C. à café', 'C. à soupe'];

export const INGREDIENT_UNITS_OPTIONS: StatusOptionType[] = INGREDIENT_UNITS.map((unit, index) => {
    return { value: index.toString(), label: unit };
});

export const INGREDIENT_UNIT_LABELS: string[] = [
    'Sans unité (ex: oeuf)',
    'Grammes (g)',
    'Kilogrammes (kg)',
    'Litres (l)',
    'Millilitres (ml)',
    'Centilitres (cl)',
    'Décilitres (dl)',
    'C. à café',
    'C. à soupe',
];

export const INGREDIENT_UNIT_LABELS_OPTIONS: StatusOptionType[] = INGREDIENT_UNIT_LABELS.map((unit, index) => {
    return { value: index.toString(), label: unit };
});
