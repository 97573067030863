import { DateConverter, ResourceType, InputType } from 'm6BoBuilder';
import * as yup from 'yup';
import Recipe, { RecipeProperties } from './Recipe';
import SubformEventRecipeRecipe from '../../components/form/subform/SubformEventRecipeRecipe';
import { EventRecipeRecipeProperties } from './EventRecipeRecipe';

const EventRecipeProperties = {
    id: 'id',
    title: 'title',
    publishedAtStart: 'publishedAtStart',
    publishedAtEnd: 'publishedAtEnd',
    eventRecipeRecipes: 'eventRecipeRecipes',
    totalRecipes: 'totalRecipes',
};

const EventRecipe: ResourceType = {
    resourcePath: 'bo-event_recipes',
    placeHolder: {
        [EventRecipeProperties.title]: '',
        [EventRecipeProperties.publishedAtStart]: null,
        [EventRecipeProperties.publishedAtEnd]: null,
        [EventRecipeProperties.eventRecipeRecipes]: [],
        [EventRecipeProperties.totalRecipes]: 0,
    },

    validationSchema: yup.object({
        [EventRecipeProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Évènement',
        listInputs: [
            {
                label: 'Titre',
                property: EventRecipeProperties.title,
            },
            {
                label: 'Début de publication',
                property: EventRecipeProperties.publishedAtStart,
                type: InputType.date,
            },
            {
                label: 'Fin de publication',
                property: EventRecipeProperties.publishedAtEnd,
                type: InputType.date,
            },
            {
                label: 'Recettes',
                property: EventRecipeProperties.eventRecipeRecipes,
                type: InputType.subform,
                options: {
                    subform: SubformEventRecipeRecipe,
                    sortable: {
                        weight: EventRecipeRecipeProperties.weight,
                    },
                },
            },
        ],
    },

    configList: {
        label: 'Évènements',
        listProperties: [
            {
                label: 'ID',
                property: EventRecipeProperties.id,
                sortable: true,
            },
            {
                label: 'Titre',
                property: EventRecipeProperties.title,
                sortable: true,
            },
            {
                label: 'Nombre de recettes',
                property: EventRecipeProperties.totalRecipes,
            },
            {
                label: 'Début de publication',
                property: EventRecipeProperties.publishedAtStart,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Fin de publication',
                property: EventRecipeProperties.publishedAtEnd,
                transform: DateConverter.display,
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: EventRecipeProperties.title,
            },
            {
                label: 'Recette',
                property: `${EventRecipeRecipeProperties.recipe}.id`,
                type: InputType.entity,
                options: {
                    label: RecipeProperties.title,
                    resource: Recipe.resourcePath,
                },
            },
        ],
    },
};

export default EventRecipe;
export { EventRecipeProperties };
