import { AppState } from './app.interface';
import { useAppStore } from './app.store';

const appService = (state: AppState) => {
    return {
        _hasHydrated: state._hasHydrated,
        isOnline: state.isOnline,
        exerciceOverlayId: state.exerciceOverlayId,
        showProgramReview: state.showProgramReview,
        isLoadingRefresh: state.isLoadingRefresh,
        refreshBodyPartExerciceVideosData: state.refreshBodyPartExerciceVideosData,
        refreshTop5: state.refreshTop5,
        refreshCurrentLive: state.refreshCurrentLive,
        refreshLives: state.refreshLives,
        setExerciceOverlayId: (id?: number) => state.setExerciceOverlayId(id),
        setIsloadingRefresh: (status: boolean) => state.setIsloadingRefresh(status),
        setShowProgramReview: (showProgramReview: boolean) => state.setShowProgramReview(showProgramReview),
        setRefreshBodyPartExerciceVideosData: (id?: number, page?: number) =>
            state.setRefreshBodyPartExerciceVideosData(id, page),
        setRefreshTop5: (status?: boolean) => state.setRefreshTop5(status ?? false),
        setRefreshCurrentLive: (status: boolean) => state.setRefreshCurrentLive(status),
        setRefreshLives: (status: boolean) => state.setRefreshLives(status),
    };
};

export const useAppService = () => {
    return useAppStore(appService);
};
