import { Avatar, Stack, Typography } from '@mui/material';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import { useTheme } from '@mui/material/styles';

const UserListProfileCell = (element: any, user: any): JSX.Element => {
    const theme = useTheme();

    /*const getUserFirstname = () => {
        return user?.firstname ?? 'John';
    };

    const getUserLastname = () => {
        return user?.lastname ?? 'Doe';
    };

    const getInitialName = () => {
        return `${getUserFirstname()[0]}${getUserLastname()[0]}`;
    };

    const userAvatar = () => {
        if (user?.avatar?.thumbnail) {
            return `${process.env.REACT_APP_API_URL}${user.avatar.thumbnail}`;
        }
        return `default`;
    };*/

    return (
        <>
            <Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    {/*<Avatar
                        alt={`${getUserFirstname()} ${getUserLastname()}`}
                        src={userAvatar()}
                        sx={{
                            margin: '2px 6px 2px 2px !important',
                            cursor: 'pointer',
                        }}
                        color="inherit"
                    >{`${getInitialName()}`}</Avatar>*/}
                    <Stack direction="column" spacing={0.5} alignItems="left">
                        <Typography variant="h4">{element}</Typography>
                        <Typography variant="subtitle2">{user.email}</Typography>
                    </Stack>
                    {user.locked && (
                        <Avatar
                            alt={`Utilisateur bloqué`}
                            sx={{
                                width: 24,
                                height: 24,
                                bgcolor: theme.palette.error.main,
                            }}
                            color="error"
                        >
                            <LockPersonOutlinedIcon
                                sx={{
                                    color: theme.palette.common.white,
                                    fontSize: 14,
                                }}
                            />
                        </Avatar>
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default UserListProfileCell;
