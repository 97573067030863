/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { IconButton } from '@mui/material';
import { ApiContext, ActionType, ActionTypeProps } from 'm6BoBuilder';
import { AxiosResponse } from 'axios';
import ApiService from 'm6BoBuilder/services/ApiService';

const AsyncCSVLinkAction: ActionType = ({ config, id }: ActionTypeProps): JSX.Element => {
    const apiService: ApiService = useContext(ApiContext);
    const [csvData, setCsvData] = useState<any>([]);
    const csvLinkEl = useRef<any>(null);

    const handleDownload = (): void => {
        setCsvData([]);

        const http = apiService.config.clients['ck'];

        http.get(config.link.replace(':id', id.toString())).then((data: AxiosResponse): void => {
            if (data.data) {
                setCsvData(data.data);
            }
        });
    };

    useEffect(() => {
        if (csvData.length > 0 && csvLinkEl.current) {
            csvLinkEl.current.link.click();
        }
    }, [csvData]);

    return id ? (
        <>
            <IconButton onClick={handleDownload}>{config.icon}</IconButton>
            <CSVLink
                filename={config.label.replace(':id', id.toString())}
                data={csvData}
                separator={';'}
                ref={csvLinkEl}
            />
        </>
    ) : (
        <></>
    );
};

export default AsyncCSVLinkAction;
