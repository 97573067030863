import { Stack, Typography } from '@mui/material';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import { DateConverter } from 'm6BoBuilder';

const ListDatetimeCell = (element: any): JSX.Element => {
    return (
        <>
            <Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <AccessTimeTwoToneIcon sx={{ fontSize: 14 }} />
                    <Stack direction="column" spacing={0.5} alignItems="left">
                        <Typography sx={{ fontSize: 12 }}>{DateConverter.display(element, true)}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default ListDatetimeCell;
