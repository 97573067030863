import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const BodyPartsProperties = {
    title: 'title',
    description: 'description',
};

const BodyParts: ResourceType = {
    resourcePath: 'bo-body_parts',
    placeHolder: {
        title: '',
        description: '',
    },

    validationSchema: yup.object({
        title: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Parties du corps',
        listInputs: [
            {
                label: 'Nom',
                property: 'title',
            },
            {
                label: 'Description',
                property: 'description',
                type: InputType.text,
            },
        ],
    },

    configList: {
        label: 'Parties du corps',
        listProperties: [
            {
                label: 'Nom',
                property: BodyPartsProperties.title,
                sortable: true,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Description',
                property: BodyPartsProperties.description,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default BodyParts;
export { BodyPartsProperties };
