/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResourceEditor } from 'm6BoBuilder';
// Libs
import { Card, CardContent, CardHeader } from '@mui/material';

import { ResourceType } from 'm6BoBuilder/types/ResourceType';

type UserPropsType = {
    id?: string | number | undefined;
    title?: string;
    resource: ResourceType;
    userId?: string | number;
    errors?: any;
    onUpdateValues: (values: Record<string, any>) => void;
};

const InfosUser = ({
    id,
    title,
    resource: { service, resourcePath, configForm, validationSchema, placeHolder },
    errors,
    onUpdateValues,
}: UserPropsType): JSX.Element => {
    return (
        <Card>
            <CardHeader title={title} />
            <CardContent>
                <ResourceEditor
                    id={id}
                    resourceService={service}
                    resourceType={resourcePath}
                    config={configForm}
                    validationSchema={validationSchema}
                    placeHolder={placeHolder}
                    showHelpCta={false}
                    setResourceValue={(data) => onUpdateValues(data)}
                    noAction={true}
                    errorsAll={errors}
                />
            </CardContent>
        </Card>
    );
};

export default InfosUser;
