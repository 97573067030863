import axios, { AxiosInstance } from 'axios';
let httpYG: null | AxiosInstance = null;

if (process.env.REACT_APP_YG_API_URL) {
    httpYG = axios.create({
        baseURL: process.env.REACT_APP_YG_API_URL,
        headers: {
            'Content-type': 'application/ld+json',
            Accept: 'application/ld+json',
        },
    });
}

export default httpYG;
