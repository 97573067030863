/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
//import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AxiosError } from 'axios';
//import { Box, Button,  Card , CardContent, Drawer, Fab, Typograph } from '@mui/material';
//import { styled } from '@mui/material/styles';
//import { Close } from '@mui/icons-material';
import { ObjectSchema } from 'yup';

import { ConfigFormType } from '../types/forms/ConfigFormType';
import { ActionsType } from '../types/ActionsType';
import { RessourceMode } from '../types/ResourceType';

import { ApiContext, ApiLoading } from '../AppBoBuilder';
import { flashMessage, flashType } from './Flash';
import DefaultForm from './form/DefaultForm';
import ApiService from '../services/ApiService';
import WhiteBox from './styled/WhiteBox';

type ResourceEditorProps = {
    id?: string | number;
    resourceType: string;
    config: ConfigFormType;
    actions?: ActionsType;
    resourceService?: string;
    validationSchema: ((context: RessourceMode) => ObjectSchema<any>) | ObjectSchema<any>;
    placeHolder: Record<string, unknown>;
    defaultValues?: {
        [key: string]: any;
    };
    showHelpCta?: boolean;
    resource?: {
        [key: string]: any;
    };
    setResourceValue?: (entity: any) => void;
    noAction?: boolean;
    errorsAll?: Record<string, any>;
    inputSize?: number;
};

/*const HelpCta = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 30,
    right: 30,
    transition: theme.transitions.create(['bottom'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));*/

/*const HelpContent = styled(Box)(() => ({
    padding: 40,
}));

const CodeSnippet = styled(Card)(() => ({
    backgroundColor: '#333',
    color: 'white',
}));*/

const ResourceEditor = ({
    id,
    resourceType,
    config,
    actions,
    validationSchema,
    placeHolder,
    defaultValues = {},
    showHelpCta,
    resource,
    setResourceValue,
    noAction,
    errorsAll,
    resourceService = 'ck',
    inputSize = 12,
}: ResourceEditorProps): JSX.Element => {
    let validationObjectSchema: ObjectSchema<any>;
    if (typeof validationSchema === 'function') {
        validationObjectSchema = validationSchema(id === undefined ? RessourceMode.Create : RessourceMode.Edit);
    } else {
        validationObjectSchema = validationSchema;
    }

    const apiService: ApiService = useContext(ApiContext);
    const isApiLoading = useContext(ApiLoading);
    const [entity, setEntity] = useState({ ...placeHolder, ...defaultValues });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    //const [isDrawerHelpOpen, setDrawerHelpOpen] = useState(false);
    //const [isCopied, setCopied] = useState(false);
    const navigate = useNavigate();
    //const helpDrawerHeight = 400;
    const EditForm = config.editForm ?? DefaultForm;

    // If existing id, load values
    useEffect(() => {
        if (id !== undefined && !isApiLoading) {
            if (false === actions?.edit) {
                flashMessage('Edition non autorisée', flashType.ERROR);
                navigate(`/${resourceType}`);
            }
            if (!resource) {
                apiService
                    .get(resourceType, id, resourceService)
                    .then((data) => {
                        if (data !== null) {
                            initValues(data);
                        }
                    })
                    .catch((error: AxiosError) => {
                        flashMessage(error.response?.data['hydra:description'] ?? error.message, flashType.ERROR);
                        //history.push(`/${resourceType}`);
                    });
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }, []);

    const initValues = (data: Record<string, any>): void => {
        setLoading(true);
        const filtered = Object.getOwnPropertyNames(placeHolder).reduce(
            (obj, key) => ({ ...obj, [key]: data.data[key] }),
            {},
        );
        setEntity({ ...filtered, ...defaultValues });
        setLoading(false);
    };

    const handleSubmit = async (ev: React.SyntheticEvent) => {
        // Prevent form submit
        ev.preventDefault();
        // Yup form validation
        let values = { ...entity, ...defaultValues };
        const isFormValid = await validationObjectSchema.isValid(values);
        // If form invalide, go back to top and show error alert
        if (!isFormValid) {
            validationObjectSchema.validate(values, { abortEarly: false }).catch((err: any) => {
                const errors = err.inner.reduce((acc: any, error: any) => {
                    return {
                        ...acc,
                        [error.path]: error.message,
                    };
                }, {});
                setErrors(errors);
            });
            window.scrollTo(0, 0);
            flashMessage('Certains champs sont invalides', flashType.ERROR);
        } else {
            if (config.beforeSubmit) {
                values = config.beforeSubmit(values, id === undefined ? RessourceMode.Create : RessourceMode.Edit);
            }

            setErrors({}); // Reset errors
            if (id === undefined) {
                // empty id ==> resource creation
                apiService
                    .create(resourceType, values, resourceService)
                    .then((data) => {
                        if (data.status == 201) {
                            flashMessage(`${config.label} "${values.title ?? data.data['id']}" créé(e)`);

                            let r = true;
                            if (config.afterSubmit) {
                                r = config.afterSubmit(data.data, RessourceMode.Create);
                            }

                            if (r) {
                                navigate(`/${resourceType}`);
                            }
                        } else {
                            flashMessage(data.data['hydra:description'], flashType.ERROR);
                        }
                    })
                    .catch((error: AxiosError) => {
                        flashMessage(
                            `API response : ${error.response?.data['hydra:description'] ?? error.message}`,
                            flashType.ERROR,
                        );
                    });
            } else {
                // existing id ==> resource update
                apiService
                    .update(resourceType, id, values, resourceService)
                    .then((data) => {
                        if (data !== null) {
                            if (data.status !== 200) {
                                flashMessage(data.data['hydra:description'], flashType.ERROR);
                            } else {
                                flashMessage(`${resourceType} mis(e) à jour`, flashType.SUCCESS);
                                if (config.afterSubmit) {
                                    config.afterSubmit(data.data, RessourceMode.Edit);
                                }
                            }
                        }
                    })
                    .catch((error: AxiosError) => {
                        flashMessage(
                            `API response : ${error.response?.data['hydra:description'] ?? error.message}`,
                            flashType.ERROR,
                        );
                    });
            }
        }
    };

    // Validate single field on change
    let timer: ReturnType<typeof setTimeout>;
    const handleEntityUpdate = (entity: Record<string, unknown>, field: string) => {
        if (!resource) {
            setEntity(entity);
        }
        if (setResourceValue) {
            setResourceValue(entity);
        }
        if (typeof validationObjectSchema.fields[field] !== 'undefined') {
            clearTimeout(timer);
            timer = setTimeout(() => {
                validationObjectSchema
                    .validateAt(field, { [field]: entity[field] })
                    .then(() => {
                        // Field valid, remove from errors array
                        if (field in errors) {
                            setErrors({ ...errors, [field]: false });
                        }
                    })
                    .catch((err: any) => {
                        console.log('error : ', field);
                        // Field not valid, add to errors array
                        setErrors({ ...errors, [err.path]: err.message });
                    });
            }, 200);
        }
    };

    /*const toggleDrawer = () => {
        setDrawerHelpOpen(!isDrawerHelpOpen);
    };*/

    return (
        <>
            {!loading && (
                <WhiteBox sx={{ paddingX: 2, paddingY: 4 }}>
                    <form onSubmit={handleSubmit}>
                        <EditForm
                            entity={resource ? resource : entity}
                            resourceType={resourceType}
                            actions={actions}
                            noAction={noAction}
                            errors={{ ...errors, ...errorsAll }}
                            config={{
                                ...config,
                                listInputs: config.listInputs.map((elem) => {
                                    return { ...elem, options: { ...elem.options, service: resourceService } };
                                }),
                            }}
                            handleEntityUpdate={handleEntityUpdate}
                            inputSize={inputSize}
                        />
                    </form>
                </WhiteBox>
            )}

            {/*showHelpCta !== false && (
                <HelpCta
                    color="default"
                    onClick={toggleDrawer}
                    sx={{
                        zIndex: 5000,
                        ...(isDrawerHelpOpen && { bottom: `calc(30px + ${helpDrawerHeight}px)` }),
                    }}
                >
                    {isDrawerHelpOpen ? <Close /> : <img style={{ width: 40 }} src={joseph} />}
                </HelpCta>
            )}

            <Drawer
                open={isDrawerHelpOpen}
                anchor={'bottom'}
                hideBackdrop={false}
                sx={{
                    '& .MuiDrawer-paper': { height: helpDrawerHeight },
                }}
            >
                <HelpContent>
                    <Typography style={{ marginBottom: 25 }}>
                        En cas d&apos;erreur sur cette page, contactez{' '}
                        <a
                            target="_blank"
                            href={`mailto:support@ck.com?subject=[ck-BO] Problème sur la page ${window.location.href}`}
                            rel="noreferrer"
                        >
                            support@ck.com
                        </a>{' '}
                        en joignant le code suivant :{' '}
                    </Typography>
                    <CodeSnippet style={{ height: helpDrawerHeight / 2, overflow: 'auto', marginBottom: 25 }}>
                        <CardContent>
                            <small>
                                <pre>
                                    <code>{JSON.stringify(resource ? resource : entity, null, 2)}</code>
                                </pre>
                            </small>
                        </CardContent>
                    </CodeSnippet>
                    <CopyToClipboard
                        text={JSON.stringify(resource ? resource : entity, null, 2)}
                        onCopy={() => setCopied(true)}
                    >
                        {isCopied ? (
                            <Button disabled>Copié</Button>
                        ) : (
                            <Button variant="outlined" color="secondary">
                                Copier dans le presse-papier
                            </Button>
                        )}
                    </CopyToClipboard>
                </HelpContent>
            </Drawer>*/}
        </>
    );
};
export default ResourceEditor;
