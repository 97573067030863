class ChoiceConverter {
    static display = (
        value: string | number,
        choices?: { value: string | number | boolean; label: string }[],
    ): string | number => {
        if (null !== choices && choices?.length) {
            for (const choice of choices) {
                if (choice.value === value) {
                    return choice.label;
                }
            }
        }

        return value;
    };
}

export default ChoiceConverter;
