import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import { IconChevronUp, IconChevronDown } from '@tabler/icons';
import { NavigationElementType } from '../../../types/NavigationType';
import useConfig from '../../../berry/hooks/useConfig';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useUserService } from 'm6BoBuilder/services/State/User/user.selector';

type NavigationElementPropsType = {
    config: NavigationElementType;
};

type ElementPropsType = {
    config: NavigationElementType;
    position: 0 | 1 | 2;
    hasChildren?: boolean;
    open?: boolean;
    onClick?: () => void;
};

const Element = ({ config, position, hasChildren, open, onClick }: ElementPropsType): JSX.Element => {
    const navigate = useNavigate();
    const Icon = config.icon;
    const { borderRadius } = useConfig();
    let selected = false;
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    // Case Dashboard
    if (!pathnames.length) {
        if (config.link === '/') selected = true;
    } else {
        if (config.link === '/' + pathnames[0]) {
            selected = true;
        }
    }

    const onHandleClick = () => {
        if (!hasChildren) {
            navigate(config.link ?? '#');
        } else {
            onClick && onClick();
        }
    };

    return (
        <ListItemButton
            onClick={onHandleClick}
            selected={selected || (hasChildren && open)}
            sx={{
                borderRadius: `${borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: position > 1 ? 'transparent !important' : 'inherit',
                py: position > 1 ? 1 : 1.25,
                pl: `${position * 24}px`,
            }}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !Icon ? 18 : 36 }}>
                {Icon ? (
                    <Icon size="1.3rem" stroke={'1.5'} />
                ) : (
                    <FiberManualRecordIcon
                        sx={{
                            width: 6,
                            height: 6,
                        }}
                        fontSize={position > 0 ? 'inherit' : 'medium'}
                    />
                )}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={hasChildren && open ? 'h5' : 'body1'} color="inherit">
                        {config.title}
                    </Typography>
                }
            />

            {hasChildren ? (
                open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )
            ) : (
                ''
            )}
        </ListItemButton>
    );
};

const NavigationElement = ({ config }: NavigationElementPropsType): JSX.Element => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(false);
    const hasChildren = config.subnav ? true : false;
    const { hasRole } = useUserService();

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected);
    };

    if (!hasRole(config.accessControl)) {
        return <></>;
    }

    return (
        <>
            <Element config={config} onClick={handleClick} position={1} hasChildren={hasChildren} open={open} />
            {hasChildren && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List
                        component="div"
                        disablePadding
                        sx={{
                            position: 'relative',
                            '&:after': {
                                content: "''",
                                position: 'absolute',
                                left: '32px',
                                top: 0,
                                height: '100%',
                                width: '1px',
                                opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                                background:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.dark.light
                                        : theme.palette.primary.light,
                            },
                        }}
                    >
                        {config.subnav?.map((subconfig) => (
                            <Element key={subconfig.title} config={subconfig} position={2} />
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};
export default NavigationElement;
