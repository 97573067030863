import { useEffect, useState } from 'react';
import { /*Color, ColorButton,*/ ColorBox /*, ColorInput, ColorPalette, ColorPicker, ColorValue*/ } from 'mui-color';
import { Box, Typography } from '@mui/material';

import { FormElementPropsType } from '../../types/forms/FormElementPropsType';

const FormColorPicker = ({
    entity,
    updateEntity,
    configLine,
    fieldSize = 'medium',
}: FormElementPropsType): JSX.Element => {
    const [selectedColor, setSelectedColor] = useState<string>();
    const handleColorChange = (color: any) => {
        if (color) {
            const hexColor = `#${color.hex.toString()}`;
            entity[configLine.property] = hexColor;
            updateEntity(entity, configLine.property);
            setSelectedColor(color);
        }
    };

    useEffect(() => {
        if (entity[configLine.property]) {
            setSelectedColor(entity[configLine.property]);
        }
    }, []);
    return (
        <Box>
            <Typography component="h2" variant="h6" mb={1}>
                {configLine.label}
            </Typography>
            <ColorBox defaultValue={selectedColor} onChange={handleColorChange} />
        </Box>
    );
};
export default FormColorPicker;
