/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CardContent, Grid, Stack, Typography, Switch } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { TableListFilters, InputType, CustomLoader, FilterType, WhiteBox, DateConverter } from 'm6BoBuilder';
import DietType, { DietTypeProperties } from '../../config/resources/DietType';
import { MenuProperties } from '../../config/resources/Menu';
import { addWeeks, endOfISOWeek, format, startOfISOWeek } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

import MenuCard from './MenuCard';
import VariantRecipeModal from './VariantRecipeModal';
import { MenuType, MenuMealType, MealRecipeType, RecipeType } from './types';

import { useFilterSearchParams } from '../../m6BoBuilder/hooks/useFilterParams';
import {
    useCreateMenuMeal,
    useDeleteMealRecipe,
    useGetMenus,
    useUpdateMealRecipe,
    useUpdateStateMenu,
} from '../../services/useQueries';

const mealTypes = ['Petit-déjeuner', 'Déjeuner', 'Collation', 'Dîner'];

const configFilters: FilterType[] = [
    {
        label: 'Type',
        property: MenuProperties.type,
        type: InputType.entity,
        options: {
            label: DietTypeProperties.name,
            resource: DietType.resourcePath,
            disableClearable: true,
            disableAutocomplete: true,
        },
    },
    {
        label: 'Date',
        property: MenuProperties.publishedAt,
        type: InputType.date,
    },
];

function MenuPlanningScreen(): JSX.Element {
    const date = new Date();

    const initialFilter = {
        [MenuProperties.publishedAt]: format(date, 'yyyy-LL-dd'),
        [MenuProperties.type]: '/diet_types/1',
    };

    const initialState = {
        date: format(date, 'yyyy-LL-dd'),
        menuList: [],
        menuStates: [],
        filters: initialFilter,
    };

    const [mealRecipeToUpdate, setMealRecipeToUpdate] = useState<MealRecipeType>();
    const [menuIdToUpdate, setMenuIdToUpdate] = useState<number | null>(null);
    const [mealIdToUpdate, setMealIdToUpdate] = useState<number | null>(null);
    const [newRecipePosition, setNewRecipePosition] = useState<number | null>(null);
    const [reorderMealRecipes, setReorderMealRecipes] = useState<MealRecipeType[]>([]);
    const [variantsOpen, setVariantsOpen] = useState(false);
    const [variantsFilters, setVariantsFilters] = useState({});
    const [getFilterSearchParams] = useFilterSearchParams();
    const searchParams = getFilterSearchParams();

    const [filters, setFilters] = useState({ ...initialFilter, ...searchParams });

    const formatDate = (date: Date): string => {
        return format(date, 'yyyy-LL-dd');
    };

    const updateFilters = (filterFields: any) => {
        setFilters({ ...filters, ...filterFields });
    };

    const convertFilters = (filters: any) => {
        const date = DateConverter.convert(filters[MenuProperties.publishedAt]);
        return {
            [MenuProperties.type]: filters[MenuProperties.type],
            [`${MenuProperties.publishedAt}[after]`]: formatDate(startOfISOWeek(date)),
            [`${MenuProperties.publishedAt}[before]`]: formatDate(endOfISOWeek(date)),
        };
    };

    // QUERIES
    const { isFetching, data: { list: menuList } = { list: [], totalItems: 0 } } = useGetMenus(convertFilters(filters));
    const { mutateAsync: mutateAsyncCreateMenuMeal } = useCreateMenuMeal();
    const { mutateAsync: mutateAsyncUpdateStateMenu } = useUpdateStateMenu();
    const { mutateAsync: mutateAsyncUpdateMealRecipe } = useUpdateMealRecipe();
    const { mutateAsync: mutateAsyncDeleteMealRecipe, isSuccess: isSuccessDeleteMealRecipe } = useDeleteMealRecipe();

    // USE EFFECTS
    useEffect(() => {
        if (isSuccessDeleteMealRecipe) {
            if (reorderMealRecipes.length > 0) {
                reorderMealRecipes.map(async (mealRecipe: MealRecipeType, index) => {
                    await mutateAsyncUpdateMealRecipe({ mealRecipeId: mealRecipe.id, data: { position: index } });
                });
            }
            setReorderMealRecipes([]);
        }
    }, [isSuccessDeleteMealRecipe]);

    // HANDLERS
    const handleVariantsOpen = (mealRecipe: MealRecipeType) => {
        const dietTypeId = filters[MenuProperties.type].toString().split('/').pop();
        setVariantsFilters({ dietType: `${dietTypeId}` });
        setMealRecipeToUpdate(mealRecipe.id ? mealRecipe : undefined);
        setVariantsOpen(true);
    };

    const handleClose = () => setVariantsOpen(false);

    const handleMealRecipeDelete = async (mealRecipeId: number, otherRecipes: MealRecipeType[]) => {
        setReorderMealRecipes(otherRecipes);
        await mutateAsyncDeleteMealRecipe(mealRecipeId);
    };

    const handleMealRecipeDetails = async (recipe: RecipeType) => {
        window.open(`${process.env.REACT_APP_WWW_URL}/mes-recettes/recette?id=${recipe.id}`, '_blank');
    };

    const handleMenuStateChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const indexes = event.target.name.split('_');
        const [menuIndex] = indexes.slice(-1);

        menuList[menuIndex].state = event.target.checked;

        const updateMenu = menuList[menuIndex];

        await mutateAsyncUpdateStateMenu({ menuId: updateMenu.id, data: updateMenu });
    };

    const onVariantSelected = async (variantSelected: RecipeType) => {
        // If mealRecipeToUpdate, update existing mealRecipe
        if (mealRecipeToUpdate) {
            await mutateAsyncUpdateMealRecipe({
                mealRecipeId: mealRecipeToUpdate.id,
                data: { recipe: variantSelected['@id'] },
            });
        } else {
            // Else, if no mealRecipeToUpdate, create a new one
            await mutateAsyncCreateMenuMeal({
                recipe: variantSelected['@id'],
                meal: mealIdToUpdate,
                menu: menuIdToUpdate,
                position: newRecipePosition,
            });
        }
        handleClose();
    };

    const switchWeek = (amount: number) => {
        const date = DateConverter.convert(filters[MenuProperties.publishedAt]);
        const newDate = addWeeks(date, amount);
        setFilters({
            ...filters,
            [MenuProperties.publishedAt]: formatDate(startOfISOWeek(newDate)),
        });
    };

    const addEmptyRecipe = (menu: MenuType, position: number, day: number, recipePosition: number) => {
        // save menu and menuMeal id for futur use
        setMenuIdToUpdate(menu['@id']);
        setMealIdToUpdate(menu.menuMeals[position].meal['@id']);
        setNewRecipePosition(recipePosition);
        // Create new Empty recipe
        menu.menuMeals[position].meal.mealRecipes.push({ position: position } as MealRecipeType);
        // Update List with new recipe
        const newMenuList: MenuType[] = [...menuList];
        newMenuList[day] = menu;
    };

    const onMealRecipeUpdate = (item: any) => {
        // à revoir pour update le menu meal complet
        if (!item.destination) return;
        const dest = item.destination;
        const indexes = dest.droppableId.split('_');
        const menuIndex = indexes.at(-2);
        const menuMealIndex = indexes.at(-1);

        const updatedList = menuList[menuIndex].menuMeals[menuMealIndex].meal.mealRecipes;
        const [reorderedItem] = updatedList.splice(item.source.index, 1);
        updatedList.splice(item.destination.index, 0, reorderedItem);

        menuList[menuIndex].menuMeals[menuMealIndex].meal.mealRecipes = updatedList;

        updateMealRecipes(updatedList);
    };

    const updateMealRecipes = async (updateMealRecipes: MealRecipeType[]) => {
        const promiseList: Promise<any>[] = [];

        updateMealRecipes.forEach((mealRecipe: MealRecipeType, index: number) => {
            promiseList.push(mutateAsyncUpdateMealRecipe({ mealRecipeId: mealRecipe.id, data: { position: index } }));
        });

        await Promise.all(promiseList);
    };

    return (
        <>
            <WhiteBox>
                <CardContent>
                    <TableListFilters
                        filters={configFilters}
                        defaultValues={initialState.filters}
                        values={filters}
                        updateFilters={updateFilters}
                        disablePagination
                    />
                </CardContent>
            </WhiteBox>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                <ArrowLeft style={{ fontSize: '50px' }} onClick={() => switchWeek(-1)} />
                <Typography component="p" variant="h3">
                    {`Menus du ${format(
                        startOfISOWeek(DateConverter.convert(filters[MenuProperties.publishedAt])),
                        'cccc dd MMMM',
                        { locale: fr },
                    )} au ${format(
                        endOfISOWeek(DateConverter.convert(filters[MenuProperties.publishedAt])),
                        'cccc dd MMMM',
                        { locale: fr },
                    )}`}
                </Typography>
                <ArrowRight style={{ fontSize: '50px' }} onClick={() => switchWeek(1)} />
            </Stack>
            {isFetching && (
                <Grid container justifyContent="center" alignItems="center" mt={4}>
                    <CustomLoader />
                </Grid>
            )}
            {!isFetching &&
                menuList.map((menu: MenuType, index: number) => {
                    const menuDayDate = new Date(menu.publishedAt);
                    // Quick fix : don't show first day of array if it's sunday from previous week
                    if (index === 0 && format(menuDayDate, 'cccc dd', { locale: fr }).includes('dimanche')) {
                        return;
                    }
                    const menuKcal = menu.menuMeals.map((menuMeal) =>
                        menuMeal.meal.mealRecipes.reduce((n, elt) => n + parseInt(elt.recipe?.kcal || '0'), 0),
                    );
                    return (
                        <div key={`menu_${menu.id}`}>
                            <h2>
                                {format(menuDayDate, 'cccc dd', { locale: fr })} (
                                {menuKcal.reduce((n, kcal) => n + kcal, 0)} kcal)
                                <Switch
                                    key={`switch_${index}`}
                                    checked={menu.state}
                                    onChange={handleMenuStateChange}
                                    name={`switch_${index}`}
                                    color="success"
                                />
                            </h2>
                            <Grid key={menu.id} container spacing={3}>
                                {mealTypes.map((label, position) => {
                                    const menuMeal = menu.menuMeals.find(
                                        (menuMeal: MenuMealType) => menuMeal.meal.type == position,
                                    ) as MenuMealType;
                                    return (
                                        <Grid key={`menumeal_${menu.id}_${position}`} item xs={12} lg={3}>
                                            <WhiteBox>
                                                <MenuCard // a revoir
                                                    index={index}
                                                    label={label}
                                                    menu={menu}
                                                    menuKcal={menuKcal}
                                                    position={position}
                                                    menuMeal={menuMeal}
                                                    onUpdate={onMealRecipeUpdate}
                                                    onRecipeClick={handleVariantsOpen}
                                                    handleMealRecipeDetails={handleMealRecipeDetails}
                                                    handleMealRecipeDelete={handleMealRecipeDelete}
                                                    addEmptyRecipe={addEmptyRecipe}
                                                />
                                            </WhiteBox>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    );
                })}

            <VariantRecipeModal
                selectedMealRecipe={mealRecipeToUpdate}
                open={variantsOpen}
                onClose={handleClose}
                defaultValues={variantsFilters}
                onVariantSelected={onVariantSelected}
            ></VariantRecipeModal>
        </>
    );
}

export default MenuPlanningScreen;
