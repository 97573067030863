/* eslint-disable @typescript-eslint/no-explicit-any */
const MealRecipe: any = {
    resourcePath: 'bo-meal-recipes',
};

const MealRecipeProperties = {
    id: 'id',
    recipe: 'recipe',
    position: 'position',
};

export default MealRecipe;

export { MealRecipeProperties };
