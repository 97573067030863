import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

class BooleanConverter {
    static convert = (value: number): boolean => {
        return !!value;
    };
    static invert = (boolean: boolean): number => {
        return boolean ? 1 : 0;
    };
    static display = (input: boolean): JSX.Element => {
        const theme = useTheme();
        return input ? (
            <Chip
                key="oui"
                sx={{
                    backgroundColor: theme.palette.success.light,
                    color: theme.palette.success.dark,
                    marginRight: '5px',
                    marginBottom: '5px',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                }}
                size="small"
                label="oui"
            />
        ) : (
            <Chip
                key="non"
                sx={{
                    backgroundColor: theme.palette.error.light,
                    color: theme.palette.error.dark,
                    marginRight: '5px',
                    marginBottom: '5px',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                }}
                size="small"
                label="non"
            />
        );
    };
}

export default BooleanConverter;
