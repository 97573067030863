import { ImageConverter, InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const ArticleAuthorProperties = {
    firstName: 'firstName',
    lastName: 'lastName',
    job: 'job',
    description: 'description',
    avatar: 'avatar',
    displayName: 'displayName',
    linkedin: 'linkedin',
    nbArticles: 'nbArticles',
    nbPrivateArticles: 'nbPrivateArticles',
    totalArticles: 'totalArticles',
};

const ArticleAuthor: ResourceType = {
    resourcePath: 'bo-article-author',
    identifier: 'slug',
    placeHolder: {
        [ArticleAuthorProperties.firstName]: '',
        [ArticleAuthorProperties.lastName]: '',
        [ArticleAuthorProperties.job]: '',
        [ArticleAuthorProperties.description]: '',
        [ArticleAuthorProperties.linkedin]: '',
        [ArticleAuthorProperties.avatar]: null,
        [ArticleAuthorProperties.displayName]: '',
        [ArticleAuthorProperties.nbArticles]: 0,
        [ArticleAuthorProperties.nbPrivateArticles]: 0,
        [ArticleAuthorProperties.totalArticles]: 0,
    },
    validationSchema: yup.object({
        [ArticleAuthorProperties.firstName]: yup.string().required('Ce champ est obligatoire'),
        [ArticleAuthorProperties.lastName]: yup.string().required('Ce champ est obligatoire'),
        [ArticleAuthorProperties.job]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: "Signataire d'articles",
        listInputs: [
            {
                label: 'Nom',
                property: ArticleAuthorProperties.lastName,
            },
            {
                label: 'Prénom',
                property: ArticleAuthorProperties.firstName,
            },
            {
                label: 'Emploi',
                property: ArticleAuthorProperties.job,
            },
            {
                label: 'Description',
                property: ArticleAuthorProperties.description,
            },
            {
                label: 'Lien Linkedin',
                property: ArticleAuthorProperties.linkedin,
            },
            {
                label: 'Avatar',
                property: ArticleAuthorProperties.avatar,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
        ],
    },
    configList: {
        label: "Signataires d'articles",
        listProperties: [
            {
                label: 'Avatar',
                property: ArticleAuthorProperties.avatar,
                transform: ImageConverter.avatar,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Prénom',
                property: ArticleAuthorProperties.firstName,
                sortable: true,
            },
            {
                label: 'Nom',
                property: ArticleAuthorProperties.lastName,
                sortable: true,
            },
            {
                label: 'Emploi',
                property: ArticleAuthorProperties.job,
                sortable: true,
            },
            {
                label: "Nombre d'articles",
                property: ArticleAuthorProperties.nbArticles,
            },
            {
                label: "Nombre d'articles privés",
                property: ArticleAuthorProperties.nbPrivateArticles,
            },
            {
                label: "Nombre total d'articles",
                property: ArticleAuthorProperties.totalArticles,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Nom',
                property: ArticleAuthorProperties.lastName,
            },
            {
                label: 'Prénom',
                property: ArticleAuthorProperties.firstName,
            },
            /*{
                label: 'Statut',
                property: ArticleAuthorProperties.status,
                type: InputType.select,
                options: {
                    choices: PUBLISHED_STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },*/
        ],
    },
    actions: {
        delete: true,
    },
};

export default ArticleAuthor;
export { ArticleAuthorProperties };
