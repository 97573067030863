import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';
import { GoalProperties } from './Goal';
import { LevelProperties } from './Level';
import { BodyPartsProperties } from './BodyParts';

const UserCBProperties = {
    id: 'id',
    goals: 'goals',
    level: 'level',
    bodyParts: 'bodyParts',
};

const UserCB: ResourceType = {
    resourcePath: 'user_details',
    placeHolder: {
        [UserCBProperties.goals]: [],
        [UserCBProperties.level]: null,
        [UserCBProperties.bodyParts]: [],
    },
    validationSchema: yup.object({}),
    service: 'cb',
    configForm: {
        label: 'Infos CB',
        listInputs: [
            {
                label: 'Niveau',
                property: UserCBProperties.level,
                type: InputType.entity,
                options: {
                    resource: 'levels',
                    service: 'cb',
                    multiple: false,
                    label: LevelProperties.title,
                },
            },
            {
                label: 'Objectif',
                property: UserCBProperties.goals,
                type: InputType.entity,
                options: {
                    resource: 'goals',
                    service: 'cb',
                    multiple: true,
                    label: GoalProperties.title,
                },
            },
            {
                label: 'Zones du corps',
                property: UserCBProperties.bodyParts,
                type: InputType.entity,
                options: {
                    resource: 'body_parts',
                    service: 'cb',
                    multiple: true,
                    label: BodyPartsProperties.title,
                },
            },
        ],
    },
    configList: {
        label: '',
        listProperties: [],
        allowAdd: false,
    },
};

export default UserCB;
export { UserCBProperties };
