import { Avatar, Chip } from '@mui/material';
import { ROLE_LABEL_COLOR, SERVICE } from 'constants/common';
import { DateConverter, TagConverter } from 'm6BoBuilder';

import { SERVICE_LABEL, SERVICE_LOGO } from '../../constants';

//import NotInterestedIcon from '@mui/icons-material/NotInterested';

const Tag = (element: any, sx?: any): JSX.Element => {
    const finalLabel = element.label;
    return (
        <Chip
            {...element}
            key={finalLabel}
            size="small"
            sx={{
                ...sx,
                marginRight: '5px',
                marginBottom: '5px',
                fontWeight: 'bold',
                fontSize: '0.75rem',
            }}
        />
    );
};

const getAvatar = (label: string, src: string, alt: string) => {
    return (
        <Avatar alt={alt} src={src}>
            {label}
        </Avatar>
    );
};

const getIcon = (service: number): any => {
    switch (service) {
        case SERVICE.CROQKILOS:
            return getAvatar('CK', SERVICE_LOGO.CROQKILOS, SERVICE_LABEL.CROQKILOS);
            break;
        case SERVICE.CROQBODY:
            return getAvatar('CB', SERVICE_LOGO.CROQBODY, SERVICE_LABEL.CROQBODY);
            break;
        case SERVICE.YOGICLUB:
            return getAvatar('YC', SERVICE_LOGO.YOGICLUB, SERVICE_LABEL.YOGICLUB);
            break;
    }
};

const UserListStatusCell = (elements: any, data: any): JSX.Element[] | JSX.Element => {
    return (
        <>
            {elements.length === 0
                ? TagConverter.display('Inactif', '', {
                      backgroundColor: '#00C2A9', //'#F03801',
                      color: 'white',
                  })
                : Object.keys(elements)
                      .sort()
                      .map((elt: any) => {
                          let label = `Abonnement expiré le  ${DateConverter.display(elements[elt].expiredAt)}`;
                          const service = elements[elt].service;
                          const backgroundColor = ROLE_LABEL_COLOR[elt];
                          if (elements[elt].automaticSubscription === 1) {
                              label = 'Actif';
                          } else {
                              const dateExpiredAt = new Date(elements[elt].dateExpiredAt);
                              const now = new Date();
                              if (now < dateExpiredAt) {
                                  label = `Résiliation effective le ${DateConverter.display(elements[elt].expiredAt)}`;
                              }
                          }
                          return Tag(
                              {
                                  label: label,
                                  avatar: getIcon(service),
                              },
                              {
                                  backgroundColor: backgroundColor,
                                  color: 'white',
                              },
                          );
                      })}
        </>
    );
};

export default UserListStatusCell;
