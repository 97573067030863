import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';
import BodyParts, { BodyPartsProperties } from 'config/resources/BodyParts';
import UserBodyPartCell from 'components/users/UserBodyPartCell';
import ListDateCell from 'components/table/ListDateCell';

const UserMeasurementProperties = {
    date: 'date',
    size: 'size',
    bodyPart: 'bodyPart',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
};

const UserMeasurement: ResourceType = {
    resourcePath: 'bo-measurements',
    placeHolder: {
        [UserMeasurementProperties.date]: null,
        [UserMeasurementProperties.size]: null,
        [UserMeasurementProperties.bodyPart]: null,
    },
    validationSchema: yup.object({}),
    configForm: {
        label: 'Abonnement',
        listInputs: [
            {
                label: 'Parties du corps',
                property: UserMeasurementProperties.bodyPart,
                type: InputType.entity,
                options: {
                    resource: BodyParts.resourcePath,
                    label: BodyPartsProperties.title,
                },
            },
            {
                label: 'Taille (en cm)',
                property: UserMeasurementProperties.size,
                type: InputType.float,
            },
            {
                label: 'Date',
                property: UserMeasurementProperties.date,
                type: InputType.date,
            },
        ],
    },
    configList: {
        label: 'Abonnements',
        listProperties: [
            {
                label: 'Parties du corps',
                property: `${UserMeasurementProperties.bodyPart}.${BodyPartsProperties.title}`,
                transform: UserBodyPartCell,
                styles: {
                    width: '30%',
                },
            },
            {
                label: 'Taille (en cm)',
                property: UserMeasurementProperties.size,
                styles: {
                    width: '30%',
                },
            },
            {
                label: 'Date',
                property: UserMeasurementProperties.date,
                transform: ListDateCell,
            },
        ],
        allowAdd: false,
    },
    /*actions: {
        delete: true,
    },*/
};

export default UserMeasurement;
export { UserMeasurementProperties };
