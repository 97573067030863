/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { /*useContext, /*useEffect,*/ useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Modal,
    Box,
    //ListItemText,
    //List,
    //ListItem,
    //ListItemButton,
    Typography,
    //ListItemAvatar,
    //Avatar,
} from '@mui/material';
import {
    TableListFilters,
    InputType,
    //ApiService,
    //ApiContext,
    CustomLoader,
    FilterType,
    //flashMessage,
    //flashType,
} from 'm6BoBuilder';
//import { AxiosError } from 'axios';
import { RecipeProperties } from '../../config/resources/Recipe';
import RecipeCategory, { RecipeCategoryProperties } from '../../config/resources/RecipeCategory';
import Paginator from '../../m6BoBuilder/components/Paginator';
import { MealRecipeType, RecipeType } from './types';
//import { ListResultType } from 'm6BoBuilder/types/ResultType';

//import { useQuery } from '@tanstack/react-query';
import { ListFilterType } from 'm6BoBuilder/types/lists/ListFilterType';
import RecipeCard from './RecipeCard';
import { useGetVariants } from '../../services/useQueries';

const styleBox = {
    position: 'absolute' as const,
    paddingTop: '10%',
    left: '10%',
    paddingBottom: '10%',
    width: '80%',
};

const configVariantFilters: FilterType[] = [
    {
        label: 'Titre',
        property: RecipeProperties.title,
        type: InputType.text,
    },
    {
        label: 'Catégorie',
        property: `${RecipeProperties.categories}.id`,
        type: InputType.entity,
        options: {
            label: RecipeCategoryProperties.title,
            resource: RecipeCategory.resourcePath,
        },
    },
];

interface VariantRecipeModalProps {
    open: boolean;
    defaultValues: any;
    selectedMealRecipe?: MealRecipeType;
    onClose: () => void;
    onVariantSelected: (variantSelected: RecipeType) => void;
}

const VariantRecipeModal = ({
    defaultValues,
    selectedMealRecipe,
    open,
    onClose,
    onVariantSelected,
}: VariantRecipeModalProps): JSX.Element => {
    const page = 1;
    const itemsPerPage = 20;
    const initialFilter: ListFilterType = {
        [`${RecipeProperties.categories}.id`]: '',
        [RecipeProperties.title]: '',
        page,
        itemsPerPage,
        ...defaultValues,
    };

    const [filters, setFilters] = useState(initialFilter);

    const dietTypeId = defaultValues['dietType'];

    // Queries
    const { isFetching, data: { list: variants, totalItems } = { list: [], totalItems: 0 } } = useGetVariants(
        filters,
        open,
        dietTypeId,
        selectedMealRecipe?.id,
    );

    const updateFilters = (filterFields: any) => {
        setFilters({ ...filters, ...filterFields });
    };

    const updateCurrentPage = (selectedPage: number) => {
        setFilters({ ...filters, page: selectedPage });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            onBackdropClick={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowY: 'scroll', maxHeight: '100vh' }}
        >
            <Box sx={styleBox}>
                <Card>
                    <CardContent sx={{ padding: '30px 20px' }}>
                        {
                            <TableListFilters
                                filters={configVariantFilters}
                                defaultValues={initialFilter}
                                values={filters}
                                updateFilters={updateFilters}
                                disablePagination
                            />
                        }
                        <Typography component="p" variant="h5" pl={2} mb={2}>
                            Remplacer Recette
                        </Typography>
                        {/*<List>
                            {isFetching ? (
                                <Grid container justifyContent="center" alignItems="center">
                                    <CustomLoader />
                                </Grid>
                            ) : (
                                variants.map((variant: VariantType) => {
                                    return (
                                        <ListItem key={`variant_${variant.id}`} disablePadding>
                                            <ListItemButton onClick={() => onVariantSelected(variant)}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        variant="rounded"
                                                        src={`${process.env.REACT_APP_API_URL}${variant.picture?.medium}`}
                                                    ></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={`${variant.title} (${variant.kcal} kcal)`} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })
                            )}
                            </List>*/}

                        <Grid container justifyContent="flex-start" alignItems="flex-start">
                            {isFetching ? (
                                <Grid container justifyContent="center" alignItems="center">
                                    <CustomLoader />
                                </Grid>
                            ) : (
                                variants.map((variant: RecipeType, index: number) => {
                                    return (
                                        <RecipeCard
                                            key={index}
                                            recipe={variant as RecipeType}
                                            onClick={() => onVariantSelected(variant)}
                                        ></RecipeCard>
                                    );
                                })
                            )}
                        </Grid>
                        {!isFetching && (
                            <Paginator
                                maxPage={Math.ceil(totalItems / itemsPerPage) ?? 1}
                                currentPage={filters.page as number}
                                updateCurrentPage={updateCurrentPage}
                            />
                        )}
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
};
export default VariantRecipeModal;
