import * as yup from 'yup';
import {
    DateConverter,
    ResourceType,
    InputType,
    BooleanConverter,
    ImageConverter,
    SelectRenderType,
} from 'm6BoBuilder';
import { PUBLISHED_STATUS_OPTIONS as STATUS_OPTIONS } from '../../constants';

const PrivateFooterBannerProperties = {
    id: 'id',
    title: 'title',
    url: 'url',
    target: 'target',
    media: 'media',
    mediaMobile: 'mediaMobile',
    status: 'status',
    publicationDate: 'publicationDate',
};

const PrivateFooterBanner: ResourceType = {
    resourcePath: 'private_footer_banners',
    placeHolder: {
        [PrivateFooterBannerProperties.id]: 0,
        [PrivateFooterBannerProperties.title]: '',
        [PrivateFooterBannerProperties.publicationDate]: undefined,
        [PrivateFooterBannerProperties.status]: 0,
        [PrivateFooterBannerProperties.url]: '',
        [PrivateFooterBannerProperties.target]: undefined,
        [PrivateFooterBannerProperties.media]: undefined,
        [PrivateFooterBannerProperties.mediaMobile]: undefined,
    },
    validationSchema: yup.object({
        [PrivateFooterBannerProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Block promotionnel privé',
        listInputs: [
            {
                label: 'Titre',
                property: PrivateFooterBannerProperties.title,
            },
            {
                label: 'Date de publication',
                property: PrivateFooterBannerProperties.publicationDate,
                type: InputType.date,
            },
            {
                label: 'Statut',
                property: PrivateFooterBannerProperties.status,
                type: InputType.select,
                options: {
                    choices: STATUS_OPTIONS,
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Bouton : Url',
                property: PrivateFooterBannerProperties.url,
            },
            {
                label: 'Bouton : Ouvrable dans un nouvel onglet',
                property: PrivateFooterBannerProperties.target,
                type: InputType.boolean,
            },
            {
                label: 'Média (1200x218px)',
                property: PrivateFooterBannerProperties.media,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Média mobile (800x800px)',
                property: PrivateFooterBannerProperties.mediaMobile,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
        ],
    },
    configList: {
        label: 'Block promotionnel privée',
        listProperties: [
            {
                label: 'ID',
                property: PrivateFooterBannerProperties.id,
                sortable: true,
            },
            {
                label: 'Image',
                property: PrivateFooterBannerProperties.media,
                transform: ImageConverter.display,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Titre',
                property: PrivateFooterBannerProperties.title,
            },
            {
                label: 'Url',
                property: PrivateFooterBannerProperties.url,
            },
            {
                label: 'Publié',
                property: PrivateFooterBannerProperties.status,
                transform: (element: string) => BooleanConverter.display(parseInt(element) > 0),
            },
            {
                label: 'Date de publication',
                property: PrivateFooterBannerProperties.publicationDate,
                transform: DateConverter.display,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: PrivateFooterBannerProperties.title,
            },
            {
                label: 'Statut',
                property: PrivateFooterBannerProperties.status,
                type: InputType.select,
                options: {
                    choices: STATUS_OPTIONS,
                    render: SelectRenderType.select,
                },
            },
        ],
        defaultOrder: {
            property: PrivateFooterBannerProperties.publicationDate,
            order: 'desc',
        },
    },
    actions: {
        delete: true,
    },
};

export default PrivateFooterBanner;
export { PrivateFooterBannerProperties };
