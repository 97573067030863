import { InputType } from './ConfigFormType';
import FormInput from '../../components/form/FormInput';
import FormSwitch from '../../components/form/FormSwitch';
import FormDate from '../../components/form/FormDate';
import FormEntity from '../../components/form/FormEntity';
import FormSelect from '../../components/form/FormSelect';
import FormSubform from '../../components/form/FormSubform';
import FormImage from '../../components/form/FormImage';
import FormDateTime from '../../components/form/FormDateTime';
import FormFile from 'm6BoBuilder/components/form/FormFile';
import FormColorPicker from '../../components/form/FormColorPicker';

const inputTypes = {
    [InputType.text]: FormInput,
    [InputType.password]: FormInput,
    [InputType.integer]: FormInput,
    [InputType.float]: FormInput,
    [InputType.boolean]: FormSwitch,
    [InputType.date]: FormDate,
    [InputType.datetime]: FormDateTime,
    [InputType.entity]: FormEntity,
    [InputType.select]: FormSelect,
    [InputType.subform]: FormSubform,
    [InputType.image]: FormImage,
    [InputType.colorPicker]: FormColorPicker,
    [InputType.file]: FormFile,
};

export default inputTypes;
